import {React, useState, useEffect} from 'react';
import { AppBar, Toolbar, Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton } from "@mui/material";
import EventSeatIcon from '@mui/icons-material/EventSeat';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MenuIcon from '@mui/icons-material/Menu';
import { useFamilyDataListener, useFirebaseAuthentication, useNewFamilyData } from '../firebase/firebase';
import PersonIcon from '@mui/icons-material/Person';
import ChairIcon from '@mui/icons-material/Chair';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import MenuBookIcon from '@mui/icons-material/MenuBook';

import UserComponent from '../components/auth/UserComponent';
import { grey } from '@mui/material/colors';
import { Link } from "react-router-dom";

const rootSx = {
  flexGrow: 1,
  backgroundColor: grey[50],
  margin: 0,
  padding: 0,
};

const growSx = {flexGrow: 1};

const listSx = {minWidth: 200};

const hiddenSX = {
  display: {
    xs: "None"
  }
}

export default function CustomAppBar(props) {
  const { uid, groupID, familyData, isAdmin} = props;
  const [drawerOpen, setDrawerOpen] = useState(false);

  let userUID = null;

  const loggedIn = userUID != null;

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  }

  const authUser = useFirebaseAuthentication();
  const members = familyData["data"] ? familyData["data"]["members"] : {};

  const throneRoom = (
  <ListItem button key={"ThroneRoom"} component={Link} to={`/${groupID}/throneRoom`}>
    <ListItemIcon><EventSeatIcon /></ListItemIcon>
    <ListItemText primary={"Throne Room"} />
  </ListItem>
  );

  const advenchoreDrawer = (
    <div
      sx={listSx}
      role="presentation"
      onClick={toggleDrawer}
      onKeyDown={toggleDrawer}
    >
      <List>
        <ListItem button key={"Dashboard"} component={Link} to={`/${groupID}/dashboard`}>
          <ListItemIcon><DashboardIcon /></ListItemIcon>
          <ListItemText primary={"Dashboard"} />
        </ListItem>
        {/* <ListItem button key={"Come Follow Me Schedule"} component={Link} to={`/${groupID}/comefollowme`}>
          <ListItemIcon><CalendarMonthIcon /></ListItemIcon>
          <ListItemText primary={"Come Follow Me Schedule"} />
        </ListItem> */}
        {isAdmin && <ListItem button key={"Throne Room"} component={Link} to={`/${groupID}/throneRoom`}>
          <ListItemIcon><ChairIcon /></ListItemIcon>
          <ListItemText primary={"Throne Room"} />
        </ListItem>}
        {isAdmin && <ListItem button key={"Chore Picking"} component={Link} to={`/${groupID}/chorePicking`}>
          <ListItemIcon><PlaylistAddCheckIcon /></ListItemIcon>
          <ListItemText primary={"Chore Picking"} />
        </ListItem>}
        {/* <ListItem button key={"Store"} component={Link} to={`/store`}>
          <ListItemIcon><ShoppingCartIcon /></ListItemIcon>
          <ListItemText primary={"Store"} />
        </ListItem> */}
        {/* <ListItem button key={"Pokemon Cards"} component={Link} to={`/pokemon`}>
          <ListItemIcon><AutoAwesomeMotionIcon /></ListItemIcon>
          <ListItemText primary={"Pokemon Cards"} />
        </ListItem> */}
        {/* <ListItem button key={"Story"} component={Link} to={`/story`}>
          <ListItemIcon><MenuBookIcon /></ListItemIcon>
          <ListItemText primary={"Story"} />
        </ListItem> */}

        {Object.keys(members).sort().map(key=>{
          return (
            <ListItem button key={key} component={Link} to={`/${groupID}/profile/${key}`}> 
              <ListItemIcon><PersonIcon  /></ListItemIcon>
              <ListItemText primary={members[key].displayName} />
            </ListItem>
          );
        })}
      </List>

    </div>
  );

  let avatar = null;


  const login = (<UserComponent user={authUser} groupID={groupID} avatar={avatar}/>);

  return (
    <div sx={rootSx}>
      <AppBar position="fixed">
        <Toolbar variant="dense" >
          <IconButton edge="start" 
          color="inherit" aria-label="menu" onClick={toggleDrawer}>
            <MenuIcon />
          </IconButton>
          <div sx={growSx} />
          {login}
        </Toolbar>
      </AppBar>
      <Toolbar variant="dense"  />
      <Drawer open={drawerOpen} onClose={toggleDrawer}>
        {advenchoreDrawer}
      </Drawer>
    </div>
  );

}



