import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, ListItem, ListItemText,List } from "@mui/material";

const hideSx = {
  display: {
    xs: "None"
  }
}
export default function ChoreActionsDialog({ onClose, open, showAdmin }) {

  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <List>
        <ListItem button sx={showAdmin ? {} : hideSx} onClick={()=>handleListItemClick("edit")} key={1}>
          <ListItemText primary="Edit" />
        </ListItem>
          <ListItem button sx={showAdmin ? {} : hideSx} onClick={()=>handleListItemClick("delete")} key={2}>
            <ListItemText primary="Delete" />
          </ListItem>
        <ListItem button sx={showAdmin ? {} : hideSx} onClick={()=>handleListItemClick("reassign")} key={4}>
          <ListItemText primary="Reassign" />
        </ListItem>
        <ListItem button onClick={()=>handleListItemClick("complete")} key={5}>
          <ListItemText primary="Mark Complete" />
        </ListItem>
        <ListItem button sx={showAdmin ? {} : hideSx} onClick={()=>handleListItemClick("today")} key={6}>
          <ListItemText primary="Due Today" />
        </ListItem>
      </List>
    </Dialog>
  );
}

ChoreActionsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  choreData: PropTypes.object,
};
