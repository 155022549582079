import React, { useState, useEffect } from 'react';
import { capitalizeFirstLetter, getChoreDataOnCompletion, batchReassignChores } from '../firebase/FirebaseDB';
import { addChoreStrings, ChoreFilter } from '../util/ChoreDataUtils';
import LoadingPage from './LoadingPage';
import LockIcon from '@mui/icons-material/Lock';    
import { useSnackbar } from 'notistack';
import { useParams, useNavigate } from 'react-router-dom';

import { Grid, CardActionArea } from '@mui/material';
import { Avatar, Card, Button, Typography } from '@mui/material';
import { LargeOldEnglishText } from '../components/OldEnglishTypography';
import { useNewFamilyData, useFamilyDataListener } from '../firebase/firebase';
import Swal from "sweetalert2";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const dateFormat = "MMMM D";

const compStyles = {
  background: {
    minHeight: '90vh',
    minWidth: '100vw',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    margin: 0,
    padding: 0,
    backgroundAttachment: 'fixed',
  },
  gridItems: {
    width: '95vw',
    margin: 'auto',
  },
  actionButton: {
    margin: 10,
  },
  list: {
    width: '100%',
    margin: 20,
    display: 'flex',
    flexWrap: 'wrap',
  },
  logo: {
    height: 40,
  },
  formControl: {
    minWidth: 120,
    marginTop: 10,
  },
  assignedTo: {
    marginTop: 25,
  },
  reward: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  dialog: {
    minWidth: 200,
  },
  throneRow: {
    margin: 'auto',
  },
  titleRow: {
    height: 50,
  },
  taskReviewRow: {
    width: '100%',
  }
}

const familyChoreColumns = [
    { title: 'Description', field: 'description' },
    { title: 'Last Done', field: 'lastString' },
    { title: 'Due Date', field: 'dueString' },
    ];

const DisplayType = {
    DAILY: "Daily Chores",
    MONTHLY: "Monthly Chores",
    WEEKLY: "Weekly Chores"
};

const PickingPage = ({familyData}) => {

    const [ displayType, setDisplayType ] = useState(DisplayType.DAILY);
    const [ choreAssignments, setChoreAssignments] = useState({});
    const [ batterLocked, setBatterLocked ] = useState(false);
    const [ atBat, setAtBat] = useState("");
    const [ started, setStarted] = useState(false);
    const [ showAssigned, setShowAssigned ] = useState(true);

    const { groupID } = useParams();
    const newFamilyData = useNewFamilyData(groupID);

    const loading = Object.keys(newFamilyData).length === 0;
    let choresData = loading ? {} : newFamilyData["data"]["chores"];
    let memberData = loading ? {} : newFamilyData["data"]["members"];
    let roomData = loading ? {} : newFamilyData["data"]["rooms"];

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate(); 

    let chores = {};

    if (displayType == DisplayType.DAILY) {
      chores = choresData["daily"] || {};  
    }
    if (displayType == DisplayType.WEEKLY) {
      chores = choresData["weekly"] || {};  
    }
    if (displayType == DisplayType.MONTHLY) {
      chores = choresData["monthly"] || {};  
    }

    let choreFilter = new ChoreFilter(chores);
    chores = choreFilter.filterChores(false, null, null, null, true);

    const getAvatar = (name) => memberData[name] ? `../../images/avatars/${memberData[name].avatar}Avatar.svg` : "";

    const onClickDisplayType = () => {
        if (displayType === DisplayType.DAILY) {
            setDisplayType(DisplayType.WEEKLY);
        } else if (displayType === DisplayType.MONTHLY) {
            setDisplayType(DisplayType.DAILY);
        } else {
            setDisplayType(DisplayType.MONTHLY);
        }
    }

    const onClickShowAssigned = () => {
      setShowAssigned(!showAssigned);
    }

    const onClickApply = () => {
        batchReassignChores(choreAssignments, groupID);
    }

    const changeAtBat = (delta) => {
      if (batterLocked) {
        return;
      } 
      const order = Object.keys(memberData);
      let newAtBat = order.indexOf(atBat) + delta;
      if (newAtBat > order.length - 1) newAtBat = 0;
      if (newAtBat < 0) newAtBat = order.length -1;
      setAtBat(order[newAtBat]);
    }

    const onChoreSelected = (data) => {
        setChoreAssignments(choreAssignments => ({
            ...choreAssignments,
            [data.id]: atBat
        }));
        changeAtBat(1);
    }

    const resetChoreAssignments = () => {
        let newAssignments = {};
        Object.keys(chores).forEach(id=>{
            newAssignments[id] = ""

        });
        setChoreAssignments(newAssignments);
        setAtBat(Object.keys(memberData).length > 0 ? Object.keys(memberData)[0] : "");
    }
  
    const onStart = () => {
      console.log('start');
      console.log(started);
      setStarted(true);
      setAtBat(Object.keys(memberData)[0]);
    }

  const onClickReset = () => {
    Swal.fire({
        title: 'Reset All Chore Assignments?',
        showCancelButton: true,
        confirmButtonText: 'Reset',
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire('Success!', '', 'success');
          resetChoreAssignments();
        } else if (result.isDenied) {
          Swal.fire('Changes are not saved', '', 'info');
        }
      })
  }

  const filter = new ChoreFilter(choresData);

  const columns = [
    { title: 'Description', field: 'description' },
    { title: 'Room', field: 'roomName' },
    ];

  const OnDeckCard = (props) => {
    const {name, avatar} = props;

    return (
        <Card sx={{backgroundColor: "#75EBE5", elevation: 5, width: 400, height: 100, margin: 2}}>
                <Grid item container direction="row" width={400} height={100}>
                    <Grid item container direction="column" xs={8}>
                        <Grid item container xs
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            sx={{bgcolor: "#75EBE5"}}>
                            <Typography component="div" align="center" variant="h6">
                            {batterLocked && <LockIcon />}UP NEXT: 
                            </Typography>
                            <Typography variant="subtitle1" align="center" color="text.secondary" component="div">
                                {name} 
                            </Typography>
                        </Grid>
                    </Grid>
                      <Grid item container xs={4}
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          sx={{bgcolor: "#75EBE5"}}>
                          <ArrowBackIosNewIcon onClick={()=>changeAtBat(-1)}/>
                          <Avatar onClick={()=>setBatterLocked(!batterLocked)} alt="Assigned" sx={{bgcolor: "blue", height: 75, width: 75}} src={avatar} />
                          <ArrowForwardIosIcon onClick={()=>changeAtBat(1)} />
                      </Grid> 
                </Grid>
        </Card>
    )
  }

  const StartCard = (props) => {
    return (
        <Card onClick={onStart} sx={{backgroundColor: "#75EBE5", elevation: 5, width: 400, height: 100, margin: 2}}>
          <CardActionArea>
            <Grid item container justifyContent="center" alignItems="center" direction="row" width={400} height={100} sx={{}}>
              <Typography component="div" align="center" variant="h3">START</Typography>
            </Grid>
          </CardActionArea>
        </Card>
    )
  }

  const ChoreCard = (props) => {
      const {chore, onChoreSelected} = props;
      const id = chore.id;
      const assignedTo = choreAssignments[id];
      const bgcolor = assignedTo === "" || assignedTo === undefined ? {"bgcolor": "#FFEC59"} : {"bgcolor": "#cbc7d6"};
    return (
        <Card sx={{elevation: 5, width: 300, height: 50, margin: 1}}>
            <CardActionArea onClick={()=>onChoreSelected(chore)}>
                <Grid item container direction="row" width={300} height={50}>
                    <Grid item container direction="column" xs={8}>
                        <Grid item container xs
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            sx={bgcolor}>
                            <Typography component="div" align="center" variant="subtitle">
                              {capitalizeFirstLetter(chore.description)}
                            </Typography>
                            <Typography variant="caption" align="center" color="text.secondary" component="div">
                                {chore.roomName}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container xs={4}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={bgcolor}>
                        <Avatar alt="Assigned" sx={{bgcolor: "blue", height: 40, width: 40}} src={getAvatar(assignedTo)} />
                    </Grid>
                </Grid>
            </CardActionArea>
        </Card>
    )
  }

  let choresList = Object.values(chores);
  choresList.sort(function(a, b){
    let x = a.room.toLowerCase();
    let y = b.room.toLowerCase();
    if (x < y) {return -1;}
    if (x > y) {return 1;}
    return 0;
  });

  return (
    <Grid container direction={"row"} sx={{padding: 1, height: "100%"}} >
      <Grid item container direction="row" spacing={1} justify="center" justifyContent="center" sx={{padding: 0}}>
        <Grid item xs = {3} sx={{}}>
          <LargeOldEnglishText text={displayType} />
        </Grid>
        <Grid item container xs={4} justifyContent="left" alignItems="center" sx={{}}> 
            <Button onClick={onClickDisplayType} variant='contained' sx={{margin: 2}}>
                TYPE
            </Button>
            <Button  onClick={onClickReset} variant='contained' sx={{margin: 2}}>
                RESET
            </Button>
            <Button  onClick={onClickApply} variant='contained' sx={{margin: 2}}>
                APPLY
            </Button>
            <Button  onClick={onClickShowAssigned} variant='contained' sx={{margin: 2}}>
                {showAssigned ? "SHOWING ASSIGNED" : "HIDING ASSIGNED"}
            </Button>
        </Grid>
        <Grid container direction="row" item xs ={5}>
            <Grid item sx={started ? {} : {display: {xs: "None"}}}><OnDeckCard name={memberData[atBat] ? memberData[atBat].displayName : ""} avatar={getAvatar(atBat)}/></Grid>
            <Grid item sx={started ? {display: {xs: "None"}} : {}}><StartCard /></Grid>
        </Grid>
        {loading && <LoadingPage />}
        <Grid container direction="row" justifyContent="center" item xs={12} sx={{bgcolor: "white"}}>
        {choresList.map((chore, index) => { 
           chore = addChoreStrings(chore, roomData);
           const unassigned = choreAssignments[chore.id] === "" || choreAssignments[chore.id] === undefined;
           if (!unassigned && !showAssigned) {
            return <></>;
           }
           return(
               <ChoreCard key={index} onChoreSelected={onChoreSelected} chore={chore}/>
           ) 
         } 
         )}

        </Grid>
      </Grid>
    </Grid>
  );
}

export default PickingPage;
