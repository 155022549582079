import { freqToDays } from '../util/Constants';
import { auth, db } from './firebase';
import { doc, getDoc, updateDoc, addDoc, setDoc, collection, deleteDoc } from "firebase/firestore";
import {createUserWithEmailAndPassword, updateProfile, sendPasswordResetEmail, sendEmailVerification} from 'firebase/auth';
import moment from 'moment';
import ConstructionOutlined from '@mui/icons-material/ConstructionOutlined';

export const dateFormat = "MMMM D";

export function registerNewUser(data) {
  createUserWithEmailAndPassword(auth, data.email, data.password).then(function() {
  var user = auth.currentUser;
  // this.createNewUser(firstName, lastName, email, user.uid, false, title);
  updateProfile(auth.currentUser, {
    displayName: data.firstName + " " + data.lastName,
  }).catch(function(error){
  });

  sendEmailVerification(user).then(function() {
      }).catch(function(error) {
      });

  }).catch(function(error) {
    });
  } 

export function registerNewUserSendResetEmail(data) {
  createUserWithEmailAndPassword(auth, data.email, random()).then(function() {
  var user = auth.currentUser;

  sendPasswordResetEmail(auth, data.email)
    .then(() => {      
     console.log('sent reset email');
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
    });

  }).catch(function(error) {
    });
}

  //registers login users to family name groupID
export const registerFamilyMembers = async(members, familyName, onRegistrationFinishedListener) => {
    const addUser = async(memberData) => {
      let data = {
        familyID: familyName,
        isAdmin: memberData["accountType"] === "admin"
      };
      const type = memberData["accountType"];
      if (type === "admin" || type === "login") {
        registerNewUserSendResetEmail(memberData);
      }
      if (memberData.uid) {
        await setDoc(doc(db, "users", memberData.uid), data);
      } else {
        await addDoc(collection(db, "users"), data);
      }
    }

    const addAll = async() => {

      for (let index = 0; index < members.length; index++) {
        const member = members[index];
        await addUser(member);
      }      
    }

    await addAll();
    setupNewFamilyDatabase(members, familyName);
    onRegistrationFinishedListener(familyName);
}

export const checkIfCastleExists = async(groupID, callback) => {
  const dataSnapshot = await getDoc(doc(db, "GroupIDs", groupID));
  callback(dataSnapshot.exists(), groupID);
}

const defaultStats = {
  armor: 0,
  character: 0,
  gold: 0,
  health: 0,
  magic: 0,
  quest: 0,
  strategy: 0,
  strength: 0,
  xp: 0,
}

const setupNewFamilyDatabase = async(members, groupID) => {
  let familyJson = {
    data: {
      chores: {
        daily: {},
        weekly: {},
        monthly: {}
      },
      members: {},
      rooms: {},
      tasks: {
        armor: {},
        health: {},
        strategy: {},
        strength: {},
        magic: {},
      }
    },
    setupComplete: true
  };

  //name, accountType, email, avatar, admin
  for (let index = 0; index < members.length; index++) {
    const member = members[index];
    const trimmedName = member.name.replace(" ","").toLowerCase();

    familyJson.data.members[trimmedName] = {
      accountType: member.accountType,
      avatar: member.avatar,
      displayName: member.name,
      email: member.email,
      stats: defaultStats
    }
  }   
  await setDoc(doc(db, "FamilyData", groupID), familyJson);
  await setDoc(doc(db, "GroupIDs", groupID), {});
}

export function createNewRoomWithChores(roomName, chores, groupID){
  const addRoom = async() => {
    const dataSnapshot = await getDoc(doc(db, "FamilyData", groupID));
    let familyData = dataSnapshot.data();
    const stripName = roomName.replace(/\s+/g, '');
    
    familyData["data"]["rooms"][stripName] = {
      "displayName": roomName
    }
    chores.forEach((chore)=>{
      createChore(stripName, chore.description, chore.frequency, null, groupID, ()=>{});
    })
  }
  addRoom();
}

export function createBatchChores(groupID, room, chores) {
  const addChores = async() => {
    const dataRef = doc(db, "FamilyData", groupID);
    const dataSnapshot = await getDoc(dataRef);
    let familyData = dataSnapshot.data();

    for (let index = 0; index < chores.length; index++) {
      const chore = chores[index];
      const newKey = random();
      
      const newData = {
        "assignedTo": chore.assignedTo || "",
        "created": moment().valueOf(),
        "description": chore.description,
        "frequency": chore.frequency,
        "id": newKey,
        "lastCompleted": null,
        "lastCompletedBy": null,
        "room": room
      }
     familyData["data"]["chores"][chore.frequency][newKey] = newData;
    }
    await updateDoc(dataRef, familyData);
  }
  addChores();
}

export function deleteRoomAndChores(groupID, roomID) {
  const update = async() => {
    const dataRef = doc(db, "FamilyData", groupID);
    const dataSnapshot = await getDoc(dataRef);
    let familyData = dataSnapshot.data();
    //Deleting chores from dict
    Object.keys(familyData["data"]["chores"]).forEach((freq)=>{
      Object.keys(familyData["data"]["chores"][freq]).forEach(id=>{
        if (familyData["data"]["chores"][freq][id].room === roomID) {
          delete familyData["data"]["chores"][freq][id];
        }
      });
    });
    delete familyData["data"]["rooms"][roomID];
    await updateDoc(dataRef, familyData);
  }

  update();

}

export function createChore(room, description, frequency, assignedTo, groupID, callback) {
  const addChore = async() => {
    const dataRef = doc(db, "FamilyData", groupID);
    const dataSnapshot = await getDoc(dataRef);
    let familyData = dataSnapshot.data();
    const newKey = random();
    const newData = {
      "assignedTo": assignedTo,
      "created": moment().valueOf(),
      "description": description,
      "frequency": frequency,
      "id": newKey,
      "lastCompleted": null,
      "lastCompletedBy": null,
      "room": room
    }
    familyData["data"]["chores"][frequency][newKey] = newData;
    callback(newData);
    await updateDoc(dataRef, familyData);
  }
  addChore();
}

// choreData is the new Data for the chore
export const updateChore = async(choreData, groupID) => {
  const updateChoreDoc = async() => {
    const dataRef = doc(db, "FamilyData", groupID);
    const dataSnapshot = await getDoc(dataRef);
    let familyData = dataSnapshot.data();
    let choresObj = familyData["data"]["chores"];
    // if switching frequency
    if (!Object.keys(choresObj[choreData.frequency]).includes(choreData.frequency)) {
      Object.keys(choresObj).forEach((freq) => {
        if (Object.keys(choresObj[freq]).includes(choreData.id) && (freq !== choreData.frequenecy)) {
          delete choresObj[freq][choreData.id];
        }
      })
      choresObj[choreData.frequency][choreData.id] = choreData; 
    } else {
      Object.keys(choreData).forEach(key=>{
        choresObj[choreData.frequency][choreData.id][key] = choreData[key]; 
      })
    }

    await updateDoc(dataRef, familyData);
  }
  if (choreData.id !== undefined) {
    updateChoreDoc();
  } else {
  }
}

export function deleteChore(choreID, groupID) {
  const del = async() => {
    const dataRef = doc(db, "FamilyData", groupID);
    const dataSnapshot = await getDoc(dataRef);
    let familyData = dataSnapshot.data();
    Object.keys(familyData["data"]["chores"]).forEach((freq)=>{
      delete familyData["data"]["chores"][freq][choreID];
    })
    updateDoc(dataRef, familyData);
  }
  del();
}

//expecting key value pair of id: assignedTo
export function batchReassignChores(data, groupID) {

  const reassign = async() => {
    const dataRef = doc(db, "FamilyData", groupID);
    const dataSnapshot = await getDoc(dataRef);
    let familyData = dataSnapshot.data();

    Object.keys(familyData["data"]["chores"]).forEach((freq)=>{
      Object.keys(data).forEach(choreID=>{
        if (choreID in familyData["data"]["chores"][freq]) {
          let choreData = familyData["data"]["chores"][freq][choreID];
          choreData.assignedTo = data[choreID];
          familyData["data"]["chores"][freq][choreID] = choreData;
        }
      })
    });

    updateDoc(dataRef, familyData);
    
    // await updateDoc(doc(db, "FamilyData",groupID,"chores", choreID), {
    // "assignedTo": assignedTo,
  };

  reassign();
}

export function reassignChore(choreID, assignedTo, description, groupID) {
  const reassign = async() => {
    const dataRef = doc(db, "FamilyData", groupID);
    const dataSnapshot = await getDoc(dataRef);
    let familyData = dataSnapshot.data();
    Object.keys(familyData["data"]["chores"]).forEach((freq)=>{
      if (choreID in familyData["data"]["chores"][freq]) {
        let choreData = familyData["data"]["chores"][freq][choreID];
        choreData.assignedTo = assignedTo;
        familyData["data"]["chores"][freq][choreID] = choreData;
      }
    })
    
    updateDoc(dataRef, familyData);
    // await updateDoc(doc(db, "FamilyData",groupID,"chores", choreID), {
    // "assignedTo": assignedTo,
  };
  // this.createEvent(uid, `chore "${capitalizeFirstLetter(description)}" assigned to ${assignedTo}`);
  reassign();
}

export function getUserData(userID, callback) {
  if (userID === null || userID === "") {
    return {}
  }
  const fetch = async() => {
    const dataRef = doc(db, "users", userID);
    const dataSnapshot = await getDoc(dataRef);
    if (dataSnapshot.exists()) {
      let userData = dataSnapshot.data();
      callback(userData);
    } else {
      callback({});
    }
  }
  fetch();
  }

export function isFamilySetupComplete(familyID, callback) {
  if (familyID === "" || familyID === undefined) {
    callback(false, familyID);
    return;
  }
  const fetch = async() => {
    const dataRef = doc(db, "FamilyData", familyID);
    const dataSnapshot = await getDoc(dataRef);
    let familyData = dataSnapshot.data();
    callback(familyData.setupComplete, familyID);
  }
  fetch();

}

export function getChoreDataOnCompletion(choreData) {
  let freq = freqToDays[choreData.frequency];
  var newDue = moment().add(freq, 'd').valueOf();
  var urgentDue = moment(newDue).add(freq, 'd').valueOf();

  return {
    "lastCompleted": moment().valueOf(),
    "lastString": moment().format(dateFormat),
    "active": true,
    "dueDate": newDue,
    "dueString": moment(newDue).format(dateFormat),
    "urgentDue": urgentDue,
    "urgentString": moment(urgentDue).format(dateFormat)
  }
}

//incoming chore data should reflect new values and not current values
export const completeChoreAndGiveReward = async(groupID, userID, choreData) => {
    const update = async() => {
      const dataRef = doc(db, "FamilyData", groupID);
      const dataSnapshot = await getDoc(dataRef);

      let familyData = dataSnapshot.data();
      familyData["data"]["chores"][choreData.frequency][choreData.id] = choreData;
      if (!familyData["data"]["members"][userID]["stats"]["xp"]) {
        familyData["data"]["members"][userID]["stats"]["xp"] = 0;
      }

      familyData["data"]["members"][userID]["stats"]["xp"] += parseInt(1);

      await updateDoc(dataRef, familyData);
    }
    if (choreData.id !== undefined) {
      update();
    } else {
    }
}

export const giveReward = async(groupID, userID, category, reward) => {
  const dataRef = doc(db, "FamilyData", groupID);
  const dataSnapshot = await getDoc(dataRef);
  let familyData = dataSnapshot.data();
  familyData["data"]["members"][userID]["stats"][category] += parseInt(reward);
  updateDoc(dataRef, familyData);
}

//expecting a non-nested object, just a dict of values
//this is called from the Throne Room Page and does not give a reward
export const markChoreComplete = async(choreData, groupID) =>{
  await updateChore(choreData, groupID);
  }

export const roomNameExists = async(groupID, roomName, chores) => {
  let shortName = roomName.replace(/[^a-z0-9]/gi, '').replace(" ","");
  const dataRef = doc(db, "FamilyData", groupID);
  const dataSnapshot = await getDoc(dataRef);
  let familyData = dataSnapshot.data();
  if (Object.keys(familyData["data"]["rooms"]).includes(shortName)) {
    return true;
  }
  return false;
}


//groupID string, roomName string, chores array
export const createRoom = async(groupID, roomName, chores) => {
  const dataRef = doc(db, "FamilyData", groupID);
  const dataSnapshot = await getDoc(dataRef);
  let familyData = dataSnapshot.data();
  let shortName = roomName.replace(/[^a-z0-9]/gi, '').replace(" ","");
  if (Object.keys(familyData["data"]["rooms"]).includes(shortName)) {
    return;
  }

  familyData["data"]["rooms"][shortName] = {
    displayName: roomName
  };

  await updateDoc(dataRef, familyData);

  createBatchChores(groupID, shortName, chores);
}


// TASK REQUESTS

export function submitTaskRequest(groupID, userid, category, index, requestText) {
  if (userid === undefined) {
    return;
  }

  const sendRequest = async() => {
    const requestRef = await addDoc(collection(db, "FamilyData",groupID,"requests"), {
      name: userid,
      category: category,
      index: index,
      timestamp: moment().valueOf(),
      explanation: requestText
    });
  };

  sendRequest();
}

export function processTaskRequest(groupID, taskID, isApproved, name, reward, taskCategory) {
  const del = async() => {
    await deleteDoc(doc(db, "FamilyData",groupID,"requests", taskID));
  }

  const giveReward = async() => {
    const dataRef = doc(db, "FamilyData", groupID);
    const dataSnapshot = await getDoc(dataRef);
    let familyData = dataSnapshot.data();
    familyData["data"]["members"][name]["stats"][taskCategory] += parseInt(reward);
    
    updateDoc(dataRef, familyData);

  }

  if (isApproved) {
    giveReward();
  }

  del();
}

export function deleteTask(groupID, taskID, category) {

  if (taskID === undefined) {
    return;
  }
  
  const edit = async() => {
    const dataRef = doc(db, "FamilyData", groupID);
    const dataSnapshot = await getDoc(dataRef);
    let familyData = dataSnapshot.data();
    delete familyData["data"]["tasks"][category][taskID];
    
    updateDoc(dataRef, familyData);
}

edit();

}

export function editTask(groupID, taskID, oldCategory, newCategory, newDescription, newQty) {
  const edit = async() => {
    const dataRef = doc(db, "FamilyData", groupID);
    const dataSnapshot = await getDoc(dataRef);
    let familyData = dataSnapshot.data();
    if (taskID === "undefined" || taskID === undefined) {
      taskID = random();
    }

    familyData["data"]["tasks"][newCategory][taskID] = {
      category: newCategory,
      description: newDescription,
      qty: parseInt(newQty),
      id: taskID,
      taskID: taskID
    };

    if (oldCategory !== undefined && oldCategory !== "undefined" && oldCategory !== newCategory) {
      delete familyData["data"]["tasks"][oldCategory][taskID];
    }
    
    updateDoc(dataRef, familyData);
  }

  edit();
}

//FEEDBACK REQUESTS

export function createFeedback(feedback, groupID) {
  const sendRequest = async() => {
    await addDoc(collection(db, "feedback"), {
      from: groupID,
      feedback: feedback,
      timestamp: moment().valueOf()
    });
  };

  sendRequest();

}

//Utils

export function capitalizeFirstLetter(input) {
  return (input && input.length > 0) ? input.charAt(0).toUpperCase() + input.slice(1) : input;
}

export const isValidEmail = (email) => {
  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return true;
  }
  return false;
}

const random = (length = 20) => {
  // Declare all characters used in UUID
  let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  // Pick characers randomly
  let str = '';
  for (let i = 0; i < length; i++) {
      str += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return str;
};
