import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';

import { CardMedia, Paper } from "@mui/material";

import swal from 'sweetalert2';
import CharacterCard from '../components/CharacterCard';

function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://mui.com/">
          Your Website
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

const useStyles = {
  root: {
    flexGrow: 1,
    width: "100%"
  }
};

const tiers = [
    {
      title: '1 Pokemon Card',
      price: '20',
      image: "/images/pokemon/1_card.png",
      description: [
        'Drawn at Random',
      ],
      buttonText: 'Redeem',
      buttonVariant: 'contained',
      reward: {
        cards: 1,
      }
    },
    {
      title: '2 Pokemon Cards',
      price: '35',
      image: "/images/pokemon/2_cards.png",
      description: [
        'Drawn at Random',
      ],
      buttonText: 'Redeem',
      buttonVariant: 'contained',
      reward: {
        cards: 2,
        redraws: 0
      }
    },
    {
      title: '3 Pokemon Cards',
      price: '50',
      image: "/images/pokemon/3_cards.png",
      description: [
        'Drawn at Random',
      ],
      buttonText: 'Redeem',
      buttonVariant: 'contained',
      reward: {
        cards: 3,
      }
    },
    {
      title: '4 Pokemon Cards',
      price: '65',
      image: "/images/pokemon/4_cards.png",
      description: [
        'Drawn at Random',
      ],
      buttonText: 'Redeem',
      buttonVariant: 'contained',
      reward: {
        cards: 4,
      }
    },
    {
      title: '5 Pokemon Cards',
      price: '75',
      subheader: 'Best Deal!!',
      image: "/images/pokemon/4_cards.png",
      description: [
        'Drawn at Random',
      ],
      buttonText: 'Redeem',
      buttonVariant: 'contained',
      reward: {
        cards: 5,
      }
    },
  ];

const StorePage = (props) => {
    const {familyData} = props;

    const [tokens, setTokens] = useState(45);

    let choresData = {};
    let memberData = {};
    let tasksData = {};
    let roomData = {};
    let statsData = {};

    if (Object.keys(familyData).length > 0) {
        choresData = familyData["data"]["chores"];
        memberData = familyData["data"]["members"];
        tasksData = familyData["data"]["tasks"];
        roomData = familyData["data"]["rooms"];
        // statsData = familyData["stats"];
    }

    const avatars = {};
    let characters = [];
    let uid = null;
    Object.keys(memberData).forEach((member) => {
      const name = member.toLowerCase();
      characters.push(name);
      if (name === props.name) {
        uid = member;
  
      }
      avatars[name] = `../../images/avatars/${memberData[member].avatar}Avatar.svg`;
    });
  
    const imgSrc = avatars[props.name]; //TODO: add a default image if none set in a || of this line
  


    const onRedeem = (cost, reward) => {
        console.log(cost, reward);
        if (cost > tokens) {
            swal.fire('You do not have enough tokens', '', 'error')

        } else {
            swal.fire('Treasure Redeemed!', '', 'success');
        }
    }
    
  return (
    <Container fixed sx={useStyles.root}>
        <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }}>
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
          >
            The Treasure Chest
          </Typography>
          <Typography variant="h5" align="center" color="text.secondary" component="p">
            Redeem your hard earned tokens for prizes!  
          </Typography>
          <Grid item container direction="row" justify="center" alignItems="flex-start">
            <Grid item container direction="row" alignItems="center" justifyContent="center" xs={12} lg={12} sx={{}}>
            {/* <CharacterCard
              name={props.name}
              displayName={"test"}
              uid={uid}
              imgSrc={imgSrc}
              groupID={props.groupID}
              toggleSelected={() => console.log('toggleSelected')}
              userData={memberData[props.name]}
              choresData={choresData}
              tasksData={tasksData}
              statsData={memberData[props.name]["stats"]}
              compact={false}
            /> */}
            </Grid>
         </Grid>
        </Container>
        {/* End hero unit */}
        <Container maxWidth="md" component="main">
          <Grid container spacing={5} alignItems="flex-end">
            {tiers.map((tier) => (
              // Enterprise card is full width at sm breakpoint
              <Grid
                item
                key={tier.title + tier.price}
                xs={12}
                sm={tier.title === 'Enterprise' ? 12 : 6}
                md={4}
              >
                <Card>
                  <CardHeader
                    title={tier.title}
                    subheader={tier.subheader}
                    titleTypographyProps={{ align: 'center' }}
                    action={tier.title === 'Pro' ? <StarIcon /> : null}
                    subheaderTypographyProps={{
                      align: 'center',
                    }}
                    sx={{
                      backgroundColor: "#9db6fc"
                    }}
                  />
                  
                    <CardMedia
                        component="img"
                        height="100"
                        image={tier.image}
                        alt="card"
                        sx={{padding: 1}}
                    />
                  <CardContent>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'baseline',
                        mb: 2,
                      }}
                    >
                      <Typography component="h2" variant="h3" color="text.primary">
                        {tier.price}
                      </Typography>
                      <Typography variant="h6" color="text.secondary">
                        tokens  
                      </Typography>
                    </Box>
                      {tier.description.map((line) => (
                        <Typography
                          component="div"
                          variant="subtitle1"
                          align="center"
                          key={line}
                        >
                          {line}
                        </Typography>
                      ))}
                  </CardContent>
                  <CardActions>
                    <Button 
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                          ? theme.palette.grey[200]
                          : theme.palette.grey[700],
                      color: "black"
                    }} onClick={()=>onRedeem(tier.price, tier.reward)} fullWidth variant={tier.buttonVariant}>
                      {tier.buttonText}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
    </Container>
    );
}

export default StorePage;
