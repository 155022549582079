import React from 'react';
import PropTypes from 'prop-types';

import { Button, Select, DialogActions, InputLabel, MenuItem, DialogTitle, DialogContent } from '@mui/material';
import { Dialog, TextField, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import NewRoomChoreCheckboxes from '../dialogs/NewRoomChoreCheckboxes';
import { useTemplateChoreData } from '../../firebase/firebase';

//This should come from the DB.  After a room is selected, it shows a number of possible chores for that kind of room.
//The user then checks which chores to add to that room.  When save is selected, it adds all those chores to the database and
// then shows a message "7 chores added to Kitchen."

export default function NewRoomDialog(props) {
  const { onClose, onCancel, open, choreData } = props;
  // const [room, setRoom] = React.useState(choreData.room || "");
  const [showTemplates, setShowTemplates] = React.useState(false);
  const [description, setDescription] = React.useState(choreData.description || "");
  // const [frequency, setFrequency] = React.useState(choreData.frequency || "");
  // const [assignedTo, setAssignedTo] = React.useState(choreData.assignedTo || "");
  const [roomTemplate, setRoomTemplate] = React.useState("");
  // const [roomTemplates, setRoomTemplates] = React.useState([]);

  const roomTemplates = useTemplateChoreData();

  let checkboxes = {};

  const toggleTemplates = (event) => {
    setShowTemplates(event.target.checked);
  };

  const handleClose = () => {
    onClose();
  };

  const handleCancel = () => {
    onCancel();
  }

  const onAddRoom = () => {
    const selectedChores = [];
    if (roomTemplate) {
      const choresList = roomTemplate.chores;
      Object.keys(choresList).forEach((key)=>{
        if (checkboxes[choresList[key].description]){
          selectedChores.push(choresList[key]);
        };
      })
    }

    onClose(description, selectedChores);
  }

  const onChange = (checkboxStates) => {
    checkboxes = checkboxStates;
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby="editDialogTitle" open={open}>
      <DialogTitle id="editDialogTitle">Add Room</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            error={description === ""}
            id="name"
            label="Room Name"
            type="string"
            value={description}
            onChange={event => setDescription(event.target.value)}
            fullWidth
            required
          />
          <br />
          <br />
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showTemplates}
                  onChange={toggleTemplates}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Add Chores from Templates"
            />
          </FormGroup>
          <br />
          <br />
          {showTemplates && <InputLabel id="assignedToLabel">Room Template</InputLabel>}
          {showTemplates && <Select
            labelId="templateLabel"
            id="templateLabel"
            value={roomTemplate}
            onChange={event => setRoomTemplate(event.target.value)}
          >
          {Object.entries(roomTemplates).map((entry, idx) => {
            return (
              <MenuItem key={idx} value={entry[1]}>
                {entry[1].displayName}
              </MenuItem>
            );
          })
          }
          </Select>}
          {roomTemplate && (
            <NewRoomChoreCheckboxes
              chores={roomTemplate.chores}
              onChange={onChange}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={onAddRoom} color="primary" autoFocus>
            Save
          </Button>
        </DialogActions>
    </Dialog>
  );
}

NewRoomDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  groupID: PropTypes.string,
  characters: PropTypes.array.isRequired,
};
