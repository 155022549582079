import React, { useState, useEffect } from 'react';
import { Avatar, Button, CssBaseline, TextField, Link, Paper, Grid, Box, Typography,
   Container, Card, CardContent, CardActionArea } from '@mui/material/';
import EventSeatOutlinedIcon from '@mui/icons-material/EventSeat';
// import { useHistory } from "react-router-dom";
import NewMemberDialog from '../components/dialogs/NewMemberDialog';
import { Firebase, useFirebaseAuthentication, useUserData } from '../firebase/firebase';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSnackbar } from 'notistack';
import { LargeOldEnglishText, MediumOldEnglishText } from '../components/OldEnglishTypography';
import { doc, getDoc } from "firebase/firestore";
import { db } from '../firebase/firebase';
import { useNavigate } from 'react-router-dom';
import { registerFamilyMembers, checkIfCastleExists } from '../firebase/FirebaseDB';
import { avatars } from '../components/dialogs/NewMemberDialog';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        advenchore.app
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = {
  paper: {
    // marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    padding: 15,
  },
  avatar: {
    // margin: theme.spacing(1),
    // backgroundColor: theme.palette.secondary.main,
  },
  characterAvatar: {
    marginTop: 20,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    opacity: 0.3,
    backgroundPosition: 'center',
    minHeight: 100,
  },
  castleInput: {
    width: '100%',
  },
  profileRowGridItem: {
    padding: 1,
  },
  avatarSelected: {
    marginTop: 20,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    borderStyle: 'solid',
    opacity: 1.0,
    backgroundPosition: 'center',
    minHeight: 100,

  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  submit: {
    // margin: theme.spacing(3, 0, 2),
  },
};

export default function FamilySetupPage({ props }) {
  const { enqueueSnackbar } = useSnackbar();
  // const history = useHistory();
  const [castleName, setCastleName] = useState("");
  const [members, setMembers] = React.useState([]);
  const [showDialog, setShowDialog] = React.useState(false);
  const [showEditDialog, setShowEditDialog] = React.useState(false);
  const [currentUser, setCurrentUser] = useState();
  const [newMember, setNewMember] = React.useState({
    name: "",
    accountType: "",
    email: "",
    avatar: ""
  })

  const navigate = useNavigate();
  const userAuth = useFirebaseAuthentication();
  
  useEffect(()=>{
    if (userAuth && members.length === 0) {
      const newMember = new Member(userAuth.uid, userAuth.displayName, "admin", userAuth.email, avatars[0], true);
      setNewMember(newMember);
      setMembers(members => members.concat(newMember));   
    }
  });


  const showErrorMessage = (message) => {
    enqueueSnackbar(message, {
        variant: 'error',
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        }});
  }

  const onEditMemberSelected = (member)=> {
    setNewMember({
      uid: member.uid,
      name: member.name,
      accountType: member.accountType,
      email: member.email,
      avatar: member.avatar,
      fieldsLocked: member.fieldsLocked
    });
    setShowEditDialog(true);

  }

  const addEditedMember = (uid, name, accountType, email, avatar, fieldsLocked) => {
    let newList = [];
      members.forEach((member, idx)=>{
        if(member.name === newMember.name) {
          newList.push(new Member(uid, name, accountType, email, avatar, fieldsLocked));
        } else {
          newList.push(member);
        }
      })
      setMembers(newList);
      setNewMember({});
      setShowEditDialog(false);
  }

  const onRegisterRequested = async() => {
    const adjustedName = castleName.split(" ").join("");
    //Validate that a castle name has been entered
    if (adjustedName.length === 0) {
      showErrorMessage("Please enter a name for your castle");
      return;
    }

    //validate that only alphanumeric characters are used
    if(!adjustedName.match(/^[0-9a-zA-Z]+$/)) {
      showErrorMessage("Please only use letters and numbers in your castle name");
      return;
    }

    //validate that at least one member is listed as admin and has an Email
    const hasAdmin = members.filter((member) => member.accountType === 'admin').length > 0;
    if (!hasAdmin) {
      showErrorMessage("At least one person should be set as Admin");
      return;
    }

    //validate that at least one member has been added
    if (members.length === 0) {
      showErrorMessage("Please add a member to your royal family");
      return;
    }

    checkIfCastleExists(adjustedName, onCastleNameChecked);
  } 
    //remove spaces from name and validate that the castle name has not already been taken
    // const castleNameListener = Firebase.database().ref(`groups/status/${adjustedName}`);
    // castleNameListener.once("value", onCastleCheckReceived);

  const onCastleNameChecked = (exists, castleName) => {
    if (exists) {
      showErrorMessage("Castle Name already exists!  Try another!");
      return;
    }

    registerFamilyMembers(members, castleName, onRegistrationFinishedListener);
  }

  const onRegistrationFinishedListener = (groupID) => {
    //redirect to dashboard
    navigate(`/${groupID}/throneRoom`);

  }

  const onAddPressed =()=> {
    setNewMember({});
    setShowDialog(true);
  }

  const deleteMember = (name) => {
    const newList = members.filter((member) => member.name !== name);
    setMembers(newList);
    }

  const addMember = (uid, name, accountType, email, avatar, fieldsLocked) => {
    const newMember = new Member(uid, name, accountType, email, avatar, fieldsLocked);
    setNewMember(newMember);
    setMembers(members => members.concat(newMember))
    setShowDialog(false);
  }

  const editMemberDialog = (
    <NewMemberDialog
        name={newMember.name}
        user={userAuth}
        uid={newMember.uid}
        accountType={newMember.accountType}
        email={newMember.email}
        avatar={newMember.avatar}
        open={showEditDialog}
        onAddMember={addEditedMember}
        fieldsLocked ={newMember.fieldsLocked} 
        onClose={() => setShowEditDialog(false)}
      />);

  const addMemberDialog = (
    <NewMemberDialog
      open={showDialog}
      onAddMember={addMember}
      onClose={() => setShowDialog(false)}
    />);

  return (
    <Container component="main" maxWidth="md" sx={useStyles.root}>
      <CssBaseline />
      <div sx={useStyles.paper} component={Paper} elevation={6}>
        <Avatar sx={useStyles.avatar}>
          <EventSeatOutlinedIcon />
        </Avatar>
        <LargeOldEnglishText text = {'Setup Your Royal Family'} />
        <MediumOldEnglishText text = {castleName ? `The Adventures of ${castleName} Castle` : ''} />
        <br />


        {showDialog && addMemberDialog}
        {showEditDialog && editMemberDialog}
        <form sx={{width: "100%"}} noValidate>
          <Grid container spacing={2} sx = {{}}>
            <Grid item xs={12} sx={useStyles.profileRowGridItem}>
              <TextField
                id="castleName"
                required
                value={castleName}
                onChange={(e) => setCastleName(e.target.value)}
                label="Castle Name" />
           </Grid>
            {members.map((member, idx) => (
              <Grid item key={idx} xs={12}>
                <ProfileRow
                   id={idx}
                   name={member.name}
                   email={member.email}
                   avatar={member.avatar}
                   onEdit={() => onEditMemberSelected(member)}
                   onDelete={deleteMember}
                 />
              </Grid>
            ))}
          <Grid item container justify="flex-start" xs={12} sx={{padding: 1}}>
            <Button id={"addMember"} variant="contained" color="primary" component="span" onClick={onAddPressed}>
              Add Member
            </Button>
          </Grid>

        </Grid>
          <Button
            fullWidth
            id={"registerButton"}
            variant="contained"
            color="primary"
            disabled={members.length === 0}
            sx={useStyles.submit}
            onClick={onRegisterRequested}
          >
            Register
          </Button>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}

function ProfileRow(props) {
  const { onEdit } = props;

  return (
    <Card style={{width: '100%'}}>
      <CardActionArea>
      <CardContent>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={2} onClick={onEdit}>
          <Avatar
            alt={"avatar"}
            src={`../../../../images/avatars/${props.avatar}Avatar.svg`}
            />
        </Grid>
        <Grid item onClick={onEdit} container direction="row" xs>
            {props.setup && <Grid item xs={12}>
            <Typography>
              Click Here to setup your character!
            </Typography>
            </Grid>}
            <Grid item xs={12}>
            <Typography id={`Name${props.name}`}>
              {props.name}
            </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography  variant="subtitle2">
                {props.email}
              </Typography>
            </Grid>
          </Grid>
          {props.onDelete && (<Grid item xs={2}>
            <DeleteIcon onClick={() => props.onDelete(props.name)}/>
          </Grid>)}
      </Grid>
      </CardContent>
      </CardActionArea>
    </Card>
  )
}

class Member {
  constructor(uid, name, accountType, email, avatar, fieldsLocked) {
    this.uid = uid;
    this.name = name;
    this.accountType = accountType;
    this.email = email;
    this.avatar = avatar;
    this.fieldsLocked = fieldsLocked;
  }
}
