import React from 'react';
import PropTypes from 'prop-types';

import { Button, Select, FormControl, DialogActions, InputLabel, MenuItem, DialogTitle, Avatar, TextField, Dialog, DialogContent } from '@mui/material';
import { useSnackbar } from 'notistack';
import { isValidEmail } from '../../firebase/FirebaseDB';
import { auth } from '../../firebase/firebase';
import { fetchSignInMethodsForEmail } from 'firebase/auth';

export const avatars = [
  'king', 
  'queen',
  'prince',
  'princessSam',
  'princessMarie'
];

export default function NewMemberDialog(props) {
  const { onClose, open, user } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [uid, setUID] = React.useState(props.uid || "");
  const [name, setName] = React.useState(props.name || "");
  // const [accountType, setAccountType] = React.useState(props.accountType || "non-login");
  const accountType = "admin";
  const [email, setEmail] = React.useState(props.email || "");
  const [avatar, setAvatar] = React.useState(props.avatar || avatars[0]);
  const [fieldsLocked, setFieldsLocked] = React.useState(props.fieldsLocked || false);

  const handleClose = () => {
    onClose();
  };

  const showErrorMessage = (message) => {
    enqueueSnackbar(message, {
        variant: 'error',
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        }});
  }

  const handleSave = () => {
    //do some field validation here
    if (name === "") {
      showErrorMessage("Please Enter a Name")
      return;
    }

    props.onAddMember(null, name, accountType, email, avatar);
  }

  // const checkEmail = () => {
  //   fetchSignInMethodsForEmail(auth, email).then((result) => {
  //     if (result.length === 0) {
  //       //email address is available, continue
  //       props.onAddMember(uid, name, accountType, email, avatar, fieldsLocked);
  //     } else if(email === user.email) {
  //       props.onAddMember(uid, name, accountType, email, avatar, fieldsLocked);
  //     } else {
  //       showErrorMessage("email address is already registered");
  //     }
  //   });
  // }

  return (
    <Dialog onClose={handleClose} aria-labelledby="addMemberDialogTitle" open={open}>
      <DialogTitle id="addMemberDialogTitle">{"Add Member"}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="string"
            value={name}
            onChange={event => setName(event.target.value)}
            fullWidth
          />
          <br />
          <InputLabel id="avatarLabel">Avatar</InputLabel>
          <Select
            labelId="avatarLabel"
            id="avatar"
            value={avatar}
            onChange={event => setAvatar(event.target.value)}
          >
          {avatars.map((avatar, idx) => (
            <MenuItem key ={idx} value={avatar}>
              <Avatar src={`../../../../images/avatars/${avatar}Avatar.svg`} />
            </MenuItem>)
          )}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button id={"saveNew"} onClick={handleSave} color="primary" autoFocus>
            Save
          </Button>
        </DialogActions>
    </Dialog>
  );
}

NewMemberDialog.propTypes = {
  onClose: PropTypes.func,
  onAddMember: PropTypes.func,
  open: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  accountType: PropTypes.string,
  email: PropTypes.string,
  avatar: PropTypes.string
};
