import React from 'react'
// import 'react-image-lightbox/style.css';
import PropTypes from 'prop-types';
import { Stack, Grid, FormGroup, FormControlLabel, Checkbox, Divider, Chip } from '@mui/material'; 
import schedule from '../data/schedule.json';

const dateColumn = (date, chapters, index) => {
  return (
    <Grid item xs={12} key={index}>
     <Stack direction="column" spacing={2} alignItems="center" divider={<Divider orientation="vertical" flexItem />}>
      <Chip label={date} variant="outlined" sx ={{width: 125}} />
      {chapters.map(c=>check(c))}
      </Stack>
     </Grid>
    
  )
}

const check = (label) => {
  return (
    <FormGroup sx ={{width: 200}}>
      <FormControlLabel control={<Checkbox defaultChecked />} label={label} />
    </FormGroup>
  )
}

const ComeFollowMeProgressPage = (props) => {

  // const display 
  return (
   <Grid container direction="row">
     {schedule.data.map((d,index)=>dateColumn(d.date, d.chapters, index))}
   </Grid>
  );
}

export default ComeFollowMeProgressPage;
