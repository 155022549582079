import React from 'react';

import { FormLabel, FormControl, FormGroup, FormControlLabel, FormHelperText, Checkbox } from '@mui/material';


const useStyles = {
  root: {
    display: 'flex',
  },
  formControl: {
    // margin: theme.spacing(3),
  },
};

export default function NewRoomChoreCheckboxes({ chores, onChange}) {

  let initState = {};
  Object.keys(chores).forEach((key, idx)=>{
    const description = chores[key].description;
    initState[description] = false;
  });

  const [state, setState] = React.useState(initState);

  const handleChange = (event) => {
    const newState = { ...state, [event.target.name]: event.target.checked };
    setState(newState);
    onChange(newState);
  };

  return (
    <div sx={useStyles.root}>
      <FormControl component="fieldset" sx={useStyles.formControl}>
        <FormLabel component="legend">Select Chores</FormLabel>
        <FormGroup>
          {Object.keys(chores).map((key, idx)=>(
            <FormControlLabel
              control={<Checkbox checked={state[chores[key].description] || false} onChange={handleChange} name={chores[key].description} />}
              key={idx}
              label={chores[key].description}
            />
          ))}
        </FormGroup>
        <FormHelperText>You can add custom chores on the next screen</FormHelperText>
      </FormControl>
    </div>
  );
}
