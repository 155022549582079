import React, { useState } from 'react';
import { Grid, Typography, Button, Paper, FormControl, InputLabel, Select, MenuItem, Badge } from '@mui/material';
import { freqToDays } from '../util/Constants';
import { capitalizeFirstLetter } from '../firebase/FirebaseDB';
import ChoreDataUtils from '../util/ChoreDataUtils';
import { MediumOldEnglishText } from './OldEnglishTypography';
import swal from 'sweetalert2';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import FirebaseStaticData from '../firebase/FirebaseStaticData';

const formControlSx = {
  // margin: theme.spacing(1),
  minWidth: 120,
};

const choreToDoSx = {
  padding: 20,
  margin: 20,
  maxWidth: '95vw',
  minWidth: '80vw',
};

const dropdownItemSx = {
  height: 45,
};

const warningSx = {
  backgroundColor: '#FFFF00',
};

const wayOverdueSx = {
  backgroundColor: 'red',
}

export const AssignedChoresPane = (props) => {
  const [room, setRoom] = useState("");
  const [roomData, setRoomData] = useState([]);

  const onRoomDataReceived = (snapshot) => {
    setRoomData(snapshot);
  }

  if(roomData.length === 0) {
    (new FirebaseStaticData(props.groupID)).getRoomData(onRoomDataReceived);
  }

  const handleChange = (event) => {
    setRoom(event.target.value);
  };

  const roomChores = props.chores.filter(function(chore) {
    return chore.room === room;
  });

  const numChores = {};
  Object.keys(roomData).forEach(function(key, index) {
    numChores[key] = props.chores.filter(c=>c.room === key).length;
  })

  return (
    <Paper key={props.id} elevation={4}  sx={choreToDoSx}>
      <Grid container direction="column">
        <Grid item>
          <MediumOldEnglishText text="Family Chores"/>
        </Grid>
        <Grid container  justify="center" item>
          <FormControl variant="outlined" sx={formControlSx}>
            <InputLabel id="demo-simple-select-outlined-label">Room</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={room}
              onChange={handleChange}
              label="Room"
            >
              {Object.keys(roomData).map(function(key, index) {
                if (numChores[key] === 0) {
                  return null;
                }
                return (
                  <MenuItem sx={dropdownItemSx} key={index} value={key}>
                    <Badge badgeContent={numChores[key]} color="primary" anchorOrigin={{ vertical: 'top', horizontal: 'right', }}>
                      {roomData[key]}
                    </Badge>
                  </MenuItem>);
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          {roomChores.map((chore, index) => {
            return (
              <ChoreRow
                key={index}
                color={getColor(chore.dueDate, chore.frequency)}
                choreID={chore.key}
                description={chore.description + " " + chore.dueDate}
                descriptions={props.descriptions}
                callback={()=>props.callback(chore.key, chore.description)}
              />);
          })}
          {props.chores.length===0 && (<span>No Chores due today, you're off the hook!</span>)}
        </Grid>
      </Grid>
    </Paper>
  )
}

function getColor(dueDate, freq) {
  var daysFreq = freqToDays[freq] || 1;
  var alertDue = dueDate.getTime() + (daysFreq * 24 * 60 * 60 * 1000);
  if (ChoreDataUtils.dueOrPastDue(alertDue)) {
    return '#FF0000'; //overdue and two or more due dates have been missed
  }
  if (dueDate.getDate() === new Date(Date.now()).getDate()) {
    return '#FFFFFF' //Due Today
  }
    return '#FFFF00'; //Overdue, but only one cycle has been missed
}

export const ChoreRow = (props) => {
  return (
    <ActionRow
      id={props.choreID}
      description={capitalizeFirstLetter(props.description || "")}
      color={props.color}
      callback={props.callback}
      buttonText={"Mark Complete"}
    />);
}

export const TaskRow = (props) => {
  return (
    <ActionRow
      id={props.task.id}
      color={props.color}
      description={props.task.description}
      callback={props.callback}
      buttonText={"Request Reward"}
      helpText={props.task.reward}
    />);
}

const ActionRow = (props) => {
  const id = props.id;
  const helpIcon = props.helpText ? (
    <Grid item xs={1}>
      <HelpOutlineIcon onClick={()=>swal(props.helpText)} />
    </Grid>) : (<React.Fragment />);

  const color = props.color || '#FFFFFF';

  return (
    <Grid key={"grid" + id} container direction="row" alignItems="center" justify="center" style={{ width: '100%', marginTop: 10, backgroundColor: color}}>
      <Grid item xs={1}>
        {helpIcon}
      </Grid>
      <Grid item container xs key={"gridDescription" + id}  justify="flex-start">
        <Typography>
          {props.description}
        </Typography>
      </Grid>
      <Grid key={"gridReward" + id} item xs={4} container justify="flex-start">
        <Button key={id} data-id={id} onClick={props.callback} variant="outlined">
          {props.buttonText}
        </Button>
      </Grid>
    </Grid>

  );
}
