export const firebaseConfig = {
    apiKey: process.env.REACT_APP_FirebaseApiKey,
    authDomain: process.env.REACT_APP_FirebaseAuthDomain,
    projectId: process.env.REACT_APP_FirebaseProjectId,
    storageBucket: process.env.REACT_APP_FirebaseStorageBucket,
    messagingSenderId: process.env.REACT_APP_FirebaseMessagingSenderId,
    messagingSenderId: process.env.REACT_APP_FirebaseMessagingSenderId,
    appId: process.env.REACT_APP_FirebaseAppId,
    databaseURL: process.env.REACT_APP_FirebaseDatabaseURL,
  };
  