import React from 'react';
import Swal from 'sweetalert2';
import { ReassignDialog } from './dialogs/ReassignDialog';
import EditChoreDialog from './dialogs/EditChoreDialog';
import ChoreActionsDialog from './dialogs/ChoreActionsDialog';
import {capitalizeFirstLetter} from '../firebase/FirebaseDB';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ChoreDataUtils, { addChoreStrings } from '../util/ChoreDataUtils';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { dateFormat, updateChore } from '../firebase/FirebaseDB';
import moment from 'moment';

const ChoresTable = (props) => {
  const {rooms, onChoreAdded, familyData} = props;
  const [showReassign, setShowReassign] = React.useState(false);
  const [showEditChore, setShowEditChore] = React.useState(false);
  const [showChoreActions, setShowChoreActions] = React.useState(false);
  const [choreData, setChoreData] = React.useState(null);
  const [selectedValue, setSelectedValue] = React.useState(props.characters[1]);
  const navigate = useNavigate();
  
  const { enqueueSnackbar } = useSnackbar();

  const handleClickOpen = (choreData) => {
    setShowReassign(true);
    setChoreData(choreData);
  };

  const handleClose = (value) => {
    setShowReassign(false);
    setSelectedValue(value);
  };

  const onActionSelect = (value) => {
    setShowChoreActions(false);
    switch(value) {
      case "reassign":
        handleClickOpen(choreData);
        break;
      case "edit":
        handleEditChore(choreData);
        break;
      case "today":
        choreData["dueDate"] = moment().valueOf();
        choreData["dueString"] = moment().format(dateFormat);
        choreData["urgentDue"] = moment().valueOf();
        updateChore(choreData, props.groupID);
        enqueueSnackbar(`Chore Now Due Today!`, {
          variant: 'success',
          anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
          },
      });
        break;
      case "delete":
        
        Swal.fire({
          title: `Delete chore? \n${choreData.roomName}: ${choreData.description}`,
          showCancelButton: true,
          confirmButtonText: 'Delete',
        }).then((result) => {
          if (result.isConfirmed) {
            
          enqueueSnackbar(`Chore Deleted`, {
            variant: 'info',
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
            },
        });
            setChoreData(null);
            setShowEditChore(false);
            props.onChoreDeleted(choreData.id);
          } else if (result.isDenied) {
            // Swal.fire('Chore kept', '', 'info');
          }
        })
        break;
      case "add":
        handleEditChore();
        break;
      case "complete":
        enqueueSnackbar(`${rooms[choreData.room].displayName}: ${choreData.description} marked as complete`, {
          variant: 'success',
          anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
          },
      });
        
        props.onChoreComplete(choreData);
        break;
      default:
        break;
      }
  }

  const handleEditClose = (value) => {
    setChoreData(null);
    setShowEditChore(false);
  };

  const handleEditChore = (choreData) => {
    setChoreData(choreData);
    setShowEditChore(true);
  }

  function onClickRow(rowData) {
    setChoreData(rowData);
    setShowChoreActions(true);
  }

  const editChoreDialog = (
    <EditChoreDialog
      open={showEditChore}
      onClose={handleEditClose}
      groupID={props.groupID}
      characters={props.characters}
      onChoreAdded={props.onChoreAdded}
      memberData={familyData && familyData["data"]? familyData["data"]["members"] : {}}
      onChoreEdited={props.onChoreEdited}
      avatars={props.avatars}
      rooms={props.rooms}
      choreData={choreData || {}}
    />
  );

  const choreActionsDialog = (
    <ChoreActionsDialog
       open={showChoreActions}
       onClose={onActionSelect}
       showAdmin={props.showAdmin}
       choreData={choreData || {}}
     />
  );

  const sortChores = (x, y) => {
    let a = props.data[x];
    let b = props.data[y];

    let roomA = a.room ? a.room.toLowerCase() : "";
    let roomB = b.room ? b.room.toLowerCase(): "";
    let descriptionA = a.description ? a.description.toLowerCase() : "";
    let descriptionB = b.description ? b.description.toLowerCase() : "";
    if (roomA < roomB) {return -1;}
    if (roomA > roomB) {return 1;}
    if (descriptionA < descriptionB) {return -1;}
    if (descriptionA > descriptionB) {return 1;}
    return 0;
  }

  Object.keys(props.data).sort(sortChores).forEach((key, index) =>{ 
    let chore = props.data[key];
  })

  return (
   <div >
     <TableContainer component={Paper}>
      <Table sx={{ width: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {props.columns.map((column, index) => (
            <TableCell key={index}>{column.title}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(props.data).sort(sortChores).map((key, index) => {
            let chore = props.data[key];
            chore = addChoreStrings(chore, props.rooms);
            return(
              <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {props.columns.map((column, index) => (
                <TableCell style={ChoreDataUtils.getStyleForChoreTable(chore, index)}  key={index} onClick={() => onClickRow(chore)} align="left">    
                  {capitalizeFirstLetter(chore[column.field])}
                  </TableCell>
              ))}
            </TableRow>
            ) 
          } 
          )}
        </TableBody>
      </Table>
    </TableContainer>

     <ReassignDialog
        selectedValue={selectedValue}
        open={showReassign}
        onClose={handleClose}
        choreData={choreData}
        memberData={familyData && familyData["data"]? familyData["data"]["members"] : {}}
        characters={props.characters}
        onReassign={props.onReassign}
        avatars={props.avatars}
        groupID={props.groupID}
      />
      {showChoreActions && choreActionsDialog}
      {showEditChore && editChoreDialog}
   </div>
  );
}

export default ChoresTable;
