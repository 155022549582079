import {React, useState, useEffect } from 'react';
import DashboardPage from './DashboardPage';
import CharacterPage from './CharacterPage'; 
import TestingPane from '../components/TestingPane'; 
import LoadingPage from './LoadingPage';
import NewSignInPage from './NewSignIn';
import NewSignUpPage from './NewSignUp';
import PickingPage from './PickingPage';
import CustomAppBar from '../components/CustomAppBar';
import { SnackbarProvider } from 'notistack';
import { Container } from '@mui/material';

import FamilySetupPage from './FamilySetupPage';
import ThroneRoomPage from './ThroneRoomPage';

import {useFamilyDataListener, useFirebaseAuthentication, db} from '../firebase/firebase';
import { collection, getDocs, doc, getDoc, onSnapshot, query, orderBy } from "firebase/firestore";
import InProgressPane from '../components/panes/InProgressPane';
import { getUserData } from '../firebase/FirebaseDB';

import {
  BrowserRouter ,
  Route, Routes,
  useParams,
} from "react-router-dom";
import ComeFollowMeProgressPage from './ComeFollowMeProgressPage';
import StorePage from './StorePage';
import { PokemonPage } from './PokemonPage';
import StoriesPage from './StoriesPage';

const App = (props) => {

    const [groupID, setGroupID] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isAdmin, setIsAdmin] = useState(false);
    const [familyData, setFamilyData] = useState({});

    useEffect(() => {
      let mounted = true;
      let unsub = () => true;
      if (groupID !== "" && groupID !== null) {
        unsub = onSnapshot(doc(db, "FamilyData", groupID), (doc) => {
            setFamilyData(doc.data());
        });
      }
      return () => {
        mounted = false;
        unsub();
      };
    }, [groupID]);

    const authUser = useFirebaseAuthentication(); 

    const onUserDataReceived = (data) => {
        if (Object.keys(data).length > 0) {
            setGroupID(data.familyID);
            setIsAdmin(data.isAdmin);
            setLoading(false);
        }        
    }

    getUserData(authUser ? authUser.uid : null, onUserDataReceived);

    function DynamicCharacterPage(props){
        const { groupID, name } = useParams();
        return (<CharacterPage name={name} groupID={groupID} familyData={props.familyData}/>);
    }
    
    return (
        <Container maxWidth="false" sx={{ maxHeight: "100vh", overflowY: "scroll" }}>
            <BrowserRouter basename='/'>
            <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}>
                {/* {authUser && loading && <LoadingPage />} */}
                {authUser && authUser.emailVerified && groupID && <CustomAppBar uid={authUser.uid} groupID={groupID} familyData={familyData} isAdmin={isAdmin}/>}
            <Routes>
                <Route exact path="/" element={<NewSignInPage />} />
                <Route exact path="/testing" element={<TestingPane />} />
                <Route exact path="/comefollowme" element={<ComeFollowMeProgressPage />} />
                <Route exact path="/signedIn" element={<DashboardPage />} />
                <Route exact path="/inProgress" element={<InProgressPane />} />
                <Route exact path="/signIn" element={<NewSignInPage />} />
                <Route exact path="/signUp" element={<NewSignUpPage />} />
                <Route exact path="/newSignUp" element={<NewSignUpPage />} />
                <Route exact path="/familySetup" element={<FamilySetupPage />} />
                <Route exact path="/store" element={<StorePage familyData={familyData}/>} />
                <Route exact path='/pokemon' element={<PokemonPage />} />
                <Route exact path='/story' element={<StoriesPage />} />
                <Route exact path="/:groupID/dashboard" element={<DashboardPage familyData={familyData} isAdmin={isAdmin}/>} />
                <Route exact path="/:groupID/throneRoom" element={<ThroneRoomPage familyData={familyData} isAdmin={isAdmin}/>} />
                <Route exact path="/:groupID/chorePicking" element={<PickingPage familyData={familyData} isAdmin={isAdmin}/>} />
                {/* <Route exact path="/:groupID/settings" element={<SettingsPage familyData={familyData} isAdmin={isAdmin}/>} /> */}
                <Route exact path="/:groupID/profile/:name" element={<DynamicCharacterPage familyData={familyData} isAdmin={isAdmin}/>} />
            </Routes>
            </SnackbarProvider>
            </BrowserRouter>
        </Container>
        );

}

export default App;
