import React, { useState } from 'react';
// import 'react-image-lightbox/style.css';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { Grid, Paper, Container } from "@mui/material";
import { FirebaseGroupManager, capitalizeFirstLetter } from '../firebase/FirebaseDB';
// import ChoreDataUtils from '../util/ChoreDataUtils';
// import FirebaseStaticData from '../firebase/FirebaseStaticData';
import { NewsFeedComponent } from '../components/NewsFeedComponent';
import { AssignedChoresPane } from '../components/AssignedChoresPane';
import CharacterCard from '../components/CharacterCard';
import { AdvenchoreTasksPane } from '../components/AdvenchoreTasksPane';

import swal from 'sweetalert2';
import moment from 'moment';
import { ChoreFilter, flattenChoresMap } from '../util/ChoreDataUtils';
import Boot from '../components/Boot';

const useStyles = {
  root: {
    flexGrow: 1,
    width: "100%"
  }
};

function showSuccess(title, text, button) {
  swal({
    title: title,
    text: text,
    icon: "success",
    button: button,
  });
}

function filterChores(choresList, assignee) {
  let chores = Object.values(choresList);
  if (chores.length === 0) {
    return [];
  }
  return chores.filter(chore => chore.assignedTo === assignee);
}

const CharacterPage = (props) => {
  const {familyData} = props;
  const [loading, setLoading] = useState(true);
  const [descriptions, setDescriptions] = useState({});
  const [taskCategory, setTaskCategory] = useState("armor");
  const navigate = useNavigate();

  // const newFamilyData = useNewFamilyData(props.groupID);
  let choresData = {};
  let memberData = {};
  let tasksData = {};
  let roomData = {};
  let statsData = {};

  if (Object.keys(familyData).length > 0) {
    choresData = familyData["data"]["chores"];
    memberData = familyData["data"]["members"];
    tasksData = familyData["data"]["tasks"];
    roomData = familyData["data"]["rooms"];
    // statsData = familyData["stats"];
    choresData = flattenChoresMap(choresData);
  }

  const choreFilter = new ChoreFilter(choresData);
  const chores = choreFilter.filterChores(true, props.name, null, null, false);

  function toggleSelectedTask(category) {
    if (taskCategory === category) {
      setTaskCategory("");
    } else {
      setTaskCategory(category);
    }
  }

  function onRefresh() {
    navigate(`/${props.groupID}/profile/${props.name}`);
  }

  function onTaskComplete(taskID, taskDescription) {
    // mgr.createRewardRequest(taskID, this.props.uid, taskDescription);
    // showSuccess("Advenchore Task Complete!", "Your rewards request has been sent to the throne room!", "Okay");
  }

  function onFamilyChoreMarkedComplete(choreID, choreDescription) {
    // mgr.markFamilyChoreComplete(choreID, this.props.uid, choreDescription);
    // this.showChoreMessage();
  }

  const avatars = {};
  let characters = [];
  let uid = null;
  Object.keys(memberData).forEach((member) => {
    const name = member.toLowerCase();
    characters.push(name);
    if (name === props.name) {
      uid = member;

    }
    avatars[name] = `../../images/avatars/${memberData[member].avatar}Avatar.svg`;
  });

  const imgSrc = avatars[props.name]; //TODO: add a default image if none set in a || of this line

  return (
    <Container fixed sx={useStyles.root}>
      <Grid item container direction="row" justify="center" alignItems="flex-start">
        {uid && (<Grid item container direction="row" alignItems="center" justifyContent="center" xs={12} lg={12} sx={{}}>
            <CharacterCard
              name={props.name}
              displayName={memberData[props.name].displayName}
              uid={uid}
              imgSrc={imgSrc}
              groupID={props.groupID}
              toggleSelected={toggleSelectedTask}
              userData={memberData[props.name]}
              choresData={choresData}
              tasksData={tasksData}
              statsData={memberData[props.name]["stats"]}
              compact={false}
            />
        </Grid>)}

        <Grid item xs={12} lg={6} container justify="center" justifyContent="center" alignItems="center" sx={{width: "100%"}}>
          <AssignedChoresPane
            descriptions={descriptions}
            chores={chores}
            callback={onFamilyChoreMarkedComplete}
            groupID={props.groupID}
            roomData={roomData}
            characters={characters}
            avatars={avatars}
            name={props.name}
            onRefresh={onRefresh}
            />
          </Grid>

          <Grid item container xs={12} lg={6} direction="column" alignItems="center" sx={{}}>
            <Paper elevation={10} sx={useStyles.advenchorePane}>
              <AdvenchoreTasksPane
                category={taskCategory}
                taskCallback={onTaskComplete}
                groupID={props.groupID}
                name={props.name}
                tasks={tasksData}/>
            </Paper>
          </Grid>
          {/* <Grid item container xs={12}>
            <Boot />
          </Grid> */}

          {/* <Grid item container xs={12} lg={3} direction="column" alignItems="center">
            <Paper elevation={10} sx={useStyles.newsfeedPaper}>
              <NewsFeedComponent
              filter={props.name}
              title={`${capitalizeFirstLetter(props.name)} News Feed`}
              groupID={props.groupID} />
            </Paper>
          </Grid> */}

      </Grid>
    </Container>
    );
}

export default CharacterPage;
