import React, {useState} from 'react';
import { Typography, Grid, Badge, Button } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ChoresTable from './ChoresTable';
import Swal from 'sweetalert2';
import { useSnackbar } from 'notistack';

const useStyles = {
  grid: {
  },
  panelDetails: {
    padding: 0,
    margin: 0,
  },
  deleteIconHidden: {
    display: {
      xs: "None"
    }
  },
  deleteIcon: {
    marginLeft: "20px",
    marginRight: "auto"
  }
};

// use localstorage to fix annoying collapsing bug
let storedState = {
  expanded: false
}

const ExpandingChoresTable = (props) => {
  const [expanded, setExpanded] = useState(false);
  const savedExpandedState = localStorage.getItem(props.panelHeading) ? 
    JSON.parse(localStorage.getItem(props.panelHeading))['expanded'] : null;
  console.log("saved in local storage:", props.panelHeading, savedExpandedState);
  if (savedExpandedState === null || savedExpandedState === undefined) {
    storedState["expanded"] = expanded;
    console.log('stored state updated to', storedState);
    localStorage.setItem(props.panelHeading, JSON.stringify(storedState));
  } else if (expanded !== savedExpandedState) {
    setExpanded(savedExpandedState === 'True' || savedExpandedState);
  }
  console.log("results in: ", props.panelHeading, expanded);

  const memberData = props.familyData && props.familyData["data"]? props.familyData["data"]["members"] : {};

  const { enqueueSnackbar } = useSnackbar();

  const toggleExpanded = () => {
    const newState = !expanded;
    localStorage.setItem(props.panelHeading, newState);
    setExpanded(newState);
  }

  const onDeleteRoom = () => {
    if (props.onDelete) {
      Swal.fire({
        title: `Delete room?`,
        text: 'All room chores will also be deleted',
        showCancelButton: true,
        confirmButtonText: 'Delete',
      }).then((result) => {
        if (result.isConfirmed) {
          
        enqueueSnackbar(`Room Deleted`, {
          variant: 'info',
          anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
          },
      });
          props.onDelete();
        } else if (result.isDenied) {
          // Swal.fire('Chore kept', '', 'info');
        }
      })
    }
  }

  return (
    <Grid item sx={useStyles.grid}>
      <MuiAccordion expanded={expanded} onChange={(event,expanded)=>toggleExpanded()}>
         <MuiAccordionSummary
           expandIcon={<ExpandMoreIcon />}
           aria-controls="panel1a-content"
           id="panel1a-header"
           sx={{backgroundColor: "#9db6fc"}}
         >
         <Badge badgeContent={Object.keys(props.data).length} color="primary">
           <Typography sx={{}}>
            {props.panelHeading}
           </Typography>
           </Badge>
           <HighlightOffIcon onClick={onDeleteRoom} sx={expanded && props.onDelete ? useStyles.deleteIcon : useStyles.deleteIconHidden} />
         </MuiAccordionSummary>
         <MuiAccordionDetails sx={useStyles.panelDetails}>
          <Grid container direction="column">
           <ChoresTable
             title={props.title}
             data={props.data}
             columns={props.columns}
             onChoreComplete={props.onChoreComplete}
             sort={props.sort}
             groupID={props.groupID}
             familyData={props.familyData}
             rooms={props.rooms}
             onChoreEdited={props.onChoreEdited}
             onChoreDeleted={props.onChoreDeleted}
             onChoreAdded={props.onChoreAdded}
             onReassign={props.onReassign}
             characters={props.characters}
             showAdmin={props.showAdmin}
             avatars={props.avatars}
           />
           </Grid>
         </MuiAccordionDetails>
       </MuiAccordion>
     </Grid>
  );
}

export default ExpandingChoresTable;
