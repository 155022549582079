export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const VERIFY_REQUEST = "VERIFY_REQUEST";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";

export const freqToDays = { 'daily': 1, 'weekly': 7, 'monthly': 30 };

export const characterInitialValues = {
  "health": 0,
  "strength": 0,
  "armor": 0,
  "strategy": 0,
  "character": 0,
  "magic": 0,
  "gold": 0
}

export const LocalStorageVariables = {
  familyData: "familyData",
  userUID: "userUID"
}
