import React from 'react';

import { Button, Typography, Input, DialogContent, Select, DialogActions, InputLabel, MenuItem, DialogTitle, TextField, Dialog  } from '@mui/material';
import { capitalizeFirstLetter } from '../../firebase/FirebaseDB';
import { useSnackbar } from 'notistack';


//EDIT TASK  ==> Description, Category, Reward
export default function EditTaskDialog(props) {
  const { onClose, open, onTaskEdited, onTaskDeleted, groupID, tasksData, categories } = props;
  const [category, setCategory] = React.useState(tasksData.category || "");
  const [description, setDescription] = React.useState(tasksData.description || "");
  const [qty, setQty] = React.useState(tasksData.qty || 1);
  
  const {enqueueSnackbar} = useSnackbar();

  const handleClose = () => {
    onClose();
  };

  const handleDelete = () => {
    onTaskDeleted(groupID, tasksData.taskID, tasksData.category);
    onClose();
  }

  const onUpdateTask = () => {
    onTaskEdited(tasksData.taskID, tasksData.category, category, description, qty);
    
    enqueueSnackbar(`Task Edited: ${description}`, {
      variant: 'success',
      anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
      },
    });

    onClose();
  }

  const title = Object.keys(tasksData).length ? "Edit Chore" : "Add Task";

  return (
    <Dialog onClose={handleClose} aria-labelledby="editDialogTitle" open={open}>
      <DialogTitle id="editDialogTitle">{title}</DialogTitle>
        <DialogContent>
          <InputLabel id="categoryLabel">Category</InputLabel>
          <Select
            labelId="categoryLabel"
            id="category"
            value={category}
            onChange={event => setCategory(event.target.value)}
          >
              {categories.map((category,index)=>{
                  return (
                    <MenuItem key={index} value={category}>
                        {capitalizeFirstLetter(category)}
                    </MenuItem>
                  )
              })}
          </Select>
          <br />
          <br />
          <TextField
            autoFocus
            margin="dense"
            id="description"
            label="Task Description"
            type="string"
            value={description}
            onChange={event => setDescription(event.target.value)}
            fullWidth
            multiline
          />
          <br />
          <br />
          
      <Typography id="input-slider" gutterBottom>
        Reward
      </Typography>
        <Input
            value={qty}
            size="small"
            onChange={event=>setQty(event.target.value)}
            inputProps={{
              step: 1,
              min: 1,
              max: 3,
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary">
            Delete
          </Button>
          <Button onClick={onUpdateTask} color="primary" autoFocus>
            Save
          </Button>
        </DialogActions>
    </Dialog>
  );
}
