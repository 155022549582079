import moment from 'moment';

export const urgencyLevel = {
  DONE: "done",
  DUE: "due",
  PAST_DUE: "past_due",
  URGENT_DUE: "urgent_due"
}


const dateFormat = "MMMM D, YYYY";

//due and urgent due must be moment objects
export function getChoreUrgency(due, urgentDue) {
  const now = moment();
  if (urgentDue.isSameOrBefore(now, 'day')) {
    return urgencyLevel.URGENT_DUE;
  }
  if (due.isBefore(now, 'day')) {
    return urgencyLevel.PAST_DUE;
  }
  if (due.isSame(now, 'day')) {
    return urgencyLevel.DUE;
  }
  return urgencyLevel.DONE;
}

export function getFreqColor(freq) {
  switch(freq) {
    case "monthly":
      return "#271D3B";
    case "weekly":
      return "#523C7A";
    case "daily":
    default:
      return "#A77AFA";
  }
}

export function getDisplayColors(due, urgentDue) {
  const urgency = getChoreUrgency(due, urgentDue);
  switch(urgency) {
    case urgencyLevel.URGENT_DUE:
      return {
        "backgroundColor": "#FF0000",
        // "background": `linear-gradient(110deg, #fdcd3b 60%, #ffed4b 60%)`,
        "color": "black"
      };
    case urgencyLevel.PAST_DUE:
      return {
        "backgroundColor": "#FFFF00",
        "color": "black"
      };
    case urgencyLevel.DUE:
      return {
        "backgroundColor": "#FFFFFF",
        // "background": `linear-gradient(110deg, #fdcd3b 60%, #ffed4b 60%)`,
        "color": "black"
      };
    case urgencyLevel.DONE:
      return {
        "backgroundColor": "white",
        "color": "black",
        // "textDecoration": "line-through"
      };
  }
}
