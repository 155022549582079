import React from 'react';
import { Grid, Container } from '@mui/material';
import pokemon from '../data/base.json';

import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';  

console.log(pokemon);

const styles = {
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '32px',
    },
    gridContainer: {
      marginTop: '32px',
      maxWidth: 1000,
    },
    card: {
      width: 199,
      height: 261,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0px',
      boxShadow: '0 15px 15px rgba(0, 0, 0, 0.2)',
      borderRadius: '10px',
    },
    logo: {
      width: 500,
      height: 125,
    },
  };

export function PokemonPage() {

  return (
    <PhotoProvider>
      <Container sx={styles.root}>
        <Grid container justifyContent="center" alignItems="center" sx={styles.gridContainer} spacing={2}>
          <Grid item xs={12} sx={{display: "flex", marginBottom: 5}} justifyContent="center" alignItems="center">
              <img style={styles.logo} src={`../../images/pokemon/pokemon_logo.png`} alt={`logo`} />
            </Grid>
          {pokemon.base.map((pokemon, i) => (
            <Grid item xs={3} key={i}>
              <PhotoView src={`../../images/pokemon/base/${pokemon}`}>
                <img style={styles.card} src={`../../images/pokemon/base/${pokemon}`} alt={`${pokemon}`} />
              </PhotoView>
            </Grid>
          ))}
        </Grid>
      </Container>
    </PhotoProvider>
  );
}

export default PokemonPage;
