import {React, useState, useEffect} from 'react'
import { useTheme } from '@mui/material/styles';

import { Grid, Paper, Typography, Popover, Box, Avatar, Stack, Tooltip, Chip } from '@mui/material';
import { MediumOldEnglishText } from './OldEnglishTypography';
import { capitalizeFirstLetter } from '../firebase/FirebaseDB';
import { urgencyLevel } from '../objects/ChoreUrgency';
import ChoreDataUtils from '../util/ChoreDataUtils';
import { useNavigate } from 'react-router-dom';

import LifeBar from './LifeBar';

import useMediaQuery from '@mui/material/useMediaQuery';


// const gold = '../../../images/icons/gold.svg';
const magic = '../../images/icons/magic.svg';
const health = '../../images/icons/health.svg';
const armor = '../../images/icons/armor.svg';
const strategy = '../../images/icons/strategy.svg';
const character = '../../images/icons/character.svg';
const strength = '../../images/icons/strength.svg';

const rootSx = {
    flexGrow: 1,
    // width: '100%',
    elevation: 15,
    padding: 0,
    margin: 2,
    // bgcolor: "orange"
  };

const treasureChestSx = {
    height: 75,
  };

const cardSx = {
    height: 200,
    // width: 300,
    elevation: 1,
  };

const popoverSx = {
    pointerEvents: 'none',
  };

const cardMediaSx = {
    height: 500,
  };

const avatarSx = {
    height: '100%',
  };

const statsSx = {
    padding: 20,
    // minWidth: 500,
    maxWidth: '75vw',
  };

const choreToDoSx = {
    padding: 20,
    // maxWidth: '75vw',
  };

const statsPaperSx = {
    padding: 20,
  };

const logoSx = {
    height: 75
  };

const hiddenSx = {
  display: {
    xs: 'None'
  }
}

const CharacterCard = (props) => {
  const { name, displayName, userData, choresData, tasksData, statsData, compact } = props;
  const [popAnchor, setPopAnchor] = useState(null);
  const [popText, setPopText] = useState("");

  const navigate = useNavigate();
  const theme = useTheme();
  const useCompactStats = useMediaQuery(theme.breakpoints.down('md'));
  
  let chores = [];
  Object.keys(choresData).forEach((key)=>{
    if (choresData[key].assignedTo === name) {
      chores.push(choresData[key]);
    }
  });

  const choreCounts = ChoreDataUtils.getChoreCounts(chores);  

  function onAvatarClick() {
    navigate(`/${props.groupID}/profile/${name.toLowerCase()}`);
  }

  function onPopoverOpen(evt, popText) {
    setPopAnchor(evt.currentTarget);
    setPopText(popText);
  }

  function onPopoverClose() {
    setPopAnchor(null);
  }

  function toggleSelected() {

  }

  let total = choreCounts ?
  choreCounts[urgencyLevel.DUE] +
  choreCounts[urgencyLevel.URGENT_DUE] +
  choreCounts[urgencyLevel.DONE] +
  choreCounts[urgencyLevel.PAST_DUE] : 0;

  const choresDone = total === 0 ? 0 : (choreCounts[urgencyLevel.DONE] / total) * 100;
  const choresDue = total === 0 ? 0 : (choreCounts[urgencyLevel.DUE] / total) * 100;
  const choresOverdue = total === 0 ? 0 : (choreCounts[urgencyLevel.PAST_DUE] / total) * 100;
  const choresUrgent = total === 0 ? 0 : (choreCounts[urgencyLevel.URGENT_DUE] / total) * 100;

  return (
  <Paper sx={rootSx}>
      <Grid container direction="row" alignItems="center" justify="center" sx={{ alignItems: "center"}}>
        <Grid item xs={3} md={3}>
        <Stack direction="column" spacing={0.5} sx={{}}>
          <LifeBar
              current={statsData.strategy || 0}
              type={"time"}
              useCompact={useCompactStats || compact}
              max={100} />
          <LifeBar
              current={statsData.xp || 0}
              type={"xp"}
              useCompact={useCompactStats || compact}
              max={100} />
          <LifeBar
              current={statsData.magic || 0}
              type={"magic"}
              useCompact={useCompactStats || compact}
              max={100} />
          </Stack>
        </Grid>
        <Grid item xs={6} md={6} container onClick={onAvatarClick} justifyContent="center" direction="column" alignItems="center" sx={{}}>
          <Box
          component="img"
          sx={{
            height: 175,
            alignItems: 'center', 
            width: 175,
          }}
          src={props.imgSrc}
        />
          
          <MediumOldEnglishText useWhiteText={false} text={capitalizeFirstLetter(props.displayName)} />
        </Grid>
        <Grid item xs={3} md={3}>
        <Stack direction="column" spacing={0.5} sx={{}}>
          <LifeBar
              current={statsData.health || 0}
              type={"health"}
              useCompact={useCompactStats || compact}
              max={100} />
          <LifeBar
              current={statsData.strength || 0}
              type={"speed"}
              useCompact={useCompactStats || compact}
              max={100} />
          <LifeBar
              current={statsData.armor|| 0}
              type={"armor"}
              useCompact={useCompactStats || compact}
              max={100} />
          </Stack>
        </Grid>

       
        <Grid item xs={12} alignItems="center" sx={{ height: 30, display: 'flex'}}>
            <Paper
              key={'choresDone'}
              onMouseEnter={(e)=>onPopoverOpen(e, `Done: ${choreCounts[urgencyLevel.DONE]}`)}
              onMouseLeave={onPopoverClose}
              style={{backgroundColor: "#D3D3D3", width: `${choresDone}%`, height: 20}}>
            </Paper>
            <Paper
              key={'choresDue'}
              onMouseEnter={(e)=>onPopoverOpen(e, `Due: ${choreCounts[urgencyLevel.DUE]}`)}
              onMouseLeave={onPopoverClose}
              style={{backgroundColor: "#008000", width: `${choresDue}%`, height: 20}}>
            </Paper>
            <Paper
              key={'choresOverdue'}
              onMouseEnter={(e)=>onPopoverOpen(e, `Overdue: ${choreCounts[urgencyLevel.PAST_DUE]}`)}
              onMouseLeave={onPopoverClose}
              style={{backgroundColor: "#FFFF00", width: `${choresOverdue}%`, height: 20}}>
            </Paper>
            <Paper
              key={'urgentChores'}
              onMouseEnter={(e)=>onPopoverOpen(e, `Urgent: ${choreCounts[urgencyLevel.URGENT_DUE]}`)}
              onMouseLeave={onPopoverClose}
              style={{backgroundColor: "#FF0000", width: `${choresUrgent}%`, height: 20}}>
            </Paper>
        </Grid>
        </Grid>
        <Popover
          id="mouse-over-popover"
          sx={popoverSx}
          open={Boolean(popAnchor)}
          anchorEl={popAnchor}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={onPopoverClose}
          disableRestoreFocus
        >
          <Typography style={{padding: 10}}>{popText}</Typography>
        </Popover>
    </Paper>
  );
}



// class OldCharacterCard extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       tasks: {},
//       stats: initStats,
//       mgr: new FirebaseGroupManager(props.groupID),
//       choreCounts: {},
//       popAnchor: null,
//       popText: "",
//     }
//     this.fetchData = this.fetchData.bind(this);
//     this.onCharStatsReceived = this.onCharStatsReceived.bind(this);
//     this.onAllTasksReceived = this.onAllTasksReceived.bind(this);
//     this.onChoreDataReceived = this.onChoreDataReceived.bind(this);
//     this.onPopoverOpen = this.onPopoverOpen.bind(this);
//     this.onPopoverClose = this.onPopoverClose.bind(this);
//     this.onAvatarClick = this.onAvatarClick.bind(this);
//   }

//   // componentDidMount () {
//   //   this.fetchData(this.props.uid);
//   // }

//   // fetchData(uid) {
//   //   const characterData = mgr.getUserData(uid);
//   //   characterData.on("value", this.onCharStatsReceived);
//   //   const tasks = mgr.getAllTasks();
//   //   tasks.on("value", this.onAllTasksReceived);
//   //   const choreListener = mgr.getAllChores();
//   //   choreListener.on("value", this.onChoreDataReceived);
//   // }

//   // componentWillUnmount() {
//   //   const characterData = mgr.getUserData(this.props.uid);
//   //   characterData.off("value");
//   //   const tasks = mgr.getAllTasks();
//   //   tasks.off("value");
//   //   const allChoresData = mgr.getAllChores();
//   //   allChoresData.off("value");
//   // }

//   // onChoreDataReceived(snapshot) {
//   //   const choreData = snapshot.val();
//   //   let chores = [];
//   //   const name = this.props.name;
//   //   Object.keys(choreData).forEach((key)=>{
//   //     if (choreData[key].assignedTo === name) {
//   //       chores.push(choreData[key]);
//   //     }
//   //   });
//   //   const choreCounts = ChoreDataUtils.getChoreCounts(chores);
//   //   this.setState({ choreCounts });
//   // }

//   // onCharStatsReceived(snapshot) {
//   //   const data = snapshot.val();
//   //   if (data==null) {
//   //     return;
//   //   }
//   //   this.setState({ stats: data.stats });
//   // }

//   // onAllTasksReceived(snapshot) {
//   //   let tasks = {};
//   //   snapshot.forEach((child) => {
//   //     var task = child.val();
//   //     var category = task.category;
//   //     task.id = child.key;
//   //     if (!tasks[category]) {
//   //       tasks[category] = [task];
//   //     } else {
//   //       tasks[category].push(task);
//   //     }
//   //   });
//   //   this.setState({ tasks });
//   // }

//   // onAvatarClick() {
//   //   window.location.href = `/${this.props.groupID}/profile/${this.props.name.toLowerCase()}`;
//   // }

//   onPopoverOpen(evt, popText) {
//     this.setState({
//       popAnchor: evt.currentTarget,
//       popText: popText,
//     });

//   }

//   onPopoverClose() {
//     this.setState({
//       popAnchor: null,
//     });

//   }

//   render() {
//     const { classes } = this.props;

//     const stats = stats;
//     if(!stats) {
//       return (<></>);
//     }

//     const oldReturn = (<div sx={rootSx}>
//       <Paper>
//         <Grid container direction="row" alignItems="center" sx={imageGridItemSx}>
//          <Grid item container direction="column" xs>
//             <StatsComponent
//               progress={stats.health}
//               sx={statsSx}
//               onClick ={this.props.toggleSelected}
//               total={30}
//               label="Health"
//               icon={health}
//               style= {{ backgroundColor: '#FFFFFF' }}
//             />
//              <StatsComponent
//                progress={stats.strength}
//                sx={statsSx}
//                onClick ={this.props.toggleSelected}
//                total={30}
//                label="Strength"
//                icon={strength}
//                style= {{ backgroundColor: '#FFFFFF' }}
//              />
//             <StatsComponent
//               progress={stats.armor}
//               sx={statsSx}
//               onClick ={this.props.toggleSelected}
//               total={30}
//               label="Armor"
//               icon={armor}
//               style= {{ backgroundColor: '#FFFFFF' }}
//             />
//          </Grid>
//          <Grid item container onClick={this.onAvatarClick} justify="center" direction="column" alignItems="center" sx={avatarCenterSx}>
//            <img alt="character logo" src={this.props.imgSrc} sx={logoSx}/>
//            <MediumOldEnglishText useWhiteText text={capitalizeFirstLetter(this.props.name)} />
//          </Grid>
//          <Grid item container direction="column" xs>
//            <StatsComponent
//              progress={stats.strategy}
//              sx={statsSx}
//              onClick ={this.props.toggleSelected}
//              total={30}
//              label="Strategy"
//              icon={strategy}
//              style= {{ backgroundColor: '#FFFFFF' }}
//            />
//             <StatsComponent
//               progress={stats.character}
//               sx={statsSx}
//               onClick={this.props.toggleSelected}
//               total={30}
//               label="Character"
//               icon={character}
//               style= {{ backgroundColor: '#FFFFFF' }}
//             />
//            <StatsComponent
//              progress={stats.magic}
//              sx={statsSx}
//              onClick={this.props.toggleSelected}
//              total={30}
//              label="Magic"
//              icon={magic}
//              style= {{ backgroundColor: '#FFFFFF' }}
//            />
//          </Grid>
//          </Grid>
//        </Paper>
//      </div>
//     );

//     let total = choreCounts ?
//       choreCounts[urgencyLevel.DUE] +
//       choreCounts[urgencyLevel.URGENT_DUE] +
//       choreCounts[urgencyLevel.DONE] +
//       choreCounts[urgencyLevel.PAST_DUE] : 0;

//       const choresDone = total === 0 ? 0 : (choreCounts[urgencyLevel.DONE] / total) * 100;
//       const choresDue = total === 0 ? 0 : (choreCounts[urgencyLevel.DUE] / total) * 100;
//       const choresOverdue = total === 0 ? 0 : (choreCounts[urgencyLevel.PAST_DUE] / total) * 100;
//       const choresUrgent = total === 0 ? 0 : (choreCounts[urgencyLevel.URGENT_DUE] / total) * 100;

//     return (
//      <div sx={rootSx}>
//          <Grid container direction="row" alignItems="center" justify="center" sx={imageGridItemSx}>
//           <Grid item container onClick={this.onAvatarClick} justify="center" direction="column" alignItems="center" sx={avatarCenterSx}>
//             <img alt="character logo" src={this.props.imgSrc} sx={logoSx}/>
//             <MediumOldEnglishText useWhiteText text={capitalizeFirstLetter(this.props.name)} />
//           </Grid>
//           <Grid item xs={12} style={{ height: 40, display: 'flex' }}>
//               <Paper
//                 key={'choresDone'}
//                 onMouseEnter={(e)=>this.onPopoverOpen(e, `Done: ${choreCounts[urgencyLevel.DONE]}`)}
//                 onMouseLeave={this.onPopoverClose}
//                 style={{backgroundColor: "#D3D3D3", width: `${choresDone}%`, height: 20}}>
//               </Paper>
//               <Paper
//                 key={'choresDue'}
//                 onMouseEnter={(e)=>this.onPopoverOpen(e, `Due: ${choreCounts[urgencyLevel.DUE]}`)}
//                 onMouseLeave={this.onPopoverClose}
//                 style={{backgroundColor: "#008000", width: `${choresDue}%`, height: 20}}>
//               </Paper>
//               <Paper
//                 key={'choresOverdue'}
//                 onMouseEnter={(e)=>this.onPopoverOpen(e, `Overdue: ${choreCounts[urgencyLevel.PAST_DUE]}`)}
//                 onMouseLeave={this.onPopoverClose}
//                 style={{backgroundColor: "#FFFF00", width: `${choresOverdue}%`, height: 20}}>
//               </Paper>
//               <Paper
//                 key={'urgentChores'}
//                 onMouseEnter={(e)=>this.onPopoverOpen(e, `Urgent: ${choreCounts[urgencyLevel.URGENT_DUE]}`)}
//                 onMouseLeave={this.onPopoverClose}
//                 style={{backgroundColor: "#FF0000", width: `${choresUrgent}%`, height: 20}}>
//               </Paper>
//           </Grid>
//           </Grid>
//           <Popover
//             id="mouse-over-popover"
//             sx={popoverSx}
//             open={Boolean(popAnchor)}
//             anchorEl={popAnchor}
//             anchorOrigin={{
//               vertical: 'bottom',
//               horizontal: 'left',
//             }}
//             transformOrigin={{
//               vertical: 'top',
//               horizontal: 'left',
//             }}
//             onClose={this.onPopoverClose}
//             disableRestoreFocus
//           >
//             <Typography style={{padding: 10}}>{popText}</Typography>
//           </Popover>
//       </div>
//     );
//   }

// }

export default CharacterCard ;
