import {React, useState, useEffect} from 'react';
import { Grid } from '@mui/material';
import { NewsFeedComponent } from '../components/NewsFeedComponent';
import { LargeOldEnglishText } from '../components/OldEnglishTypography';
import CharacterCard from '../components/CharacterCard';
import FirebaseStaticData from '../firebase/FirebaseStaticData';

import {Firebase, useFirebaseAuthentication, useFamilyData, useNewFamilyData } from '../firebase/firebase';
import { useParams } from 'react-router-dom';
import {flattenChoresMap} from '../util/ChoreDataUtils';
import { TaskReviewPane } from '../components/TaskReviewPane';
import Boot from '../components/Boot';
import InProgressPane from '../components/panes/InProgressPane';

const useStyles = {
  root: {
    // width: '100vw',
    // backgroundImage: 'url(../../images/backgrounds/inside.jpg)',
    // backgroundRepeat: 'no-repeat',
    // backgroundSize: 'cover',
    // backgroundPosition: 'center',
    // minHeight: '95vh',
    // backgroundAttachment: 'fixed',
    display: 'flex',
    // bgcolor: "purple",
    margin: 0,
    padding: 0
  },
  characterCardGrid: {
  },
  titleRow: {
    height: 75,
  },
  newsfeedPaper: {
    margin: 10,
    padding: 10,
  },
  hidden: {
    display: {
      xs: 'None'
    }
  }
};

const hiddenSX = {  
  display: {
    xs: "None"
  }
};

const DashboardPage = (props) => {
  const {familyData, isAdmin} = props;
  const { groupID } = useParams();
  const [loading, setLoading] = useState(true);

  let choresData = {};
  let memberData = {};
  let tasksData = {};
  if (Object.keys(familyData).length > 0) {
    choresData = familyData["data"]["chores"];
    memberData = familyData["data"]["members"];
    tasksData = familyData["data"]["tasks"];
  }
  choresData = flattenChoresMap(choresData);

  const user = useFirebaseAuthentication();

  const tasks = []; // TODO

  let welcomeMessage;
    if (user && user.displayName) {
      welcomeMessage = (
        <LargeOldEnglishText id={"welcome"} text={`Welcome, ${user.displayName}`} />
      );
    }

    const avatars = {};
    let names = [];
    let uids = {};
    Object.keys(memberData).map((key) => {
      // const name = memberData[key].name.toLowerCase();
      names.push(key);
      avatars[key] = `../../images/avatars/${memberData[key].avatar}Avatar.svg`;
      uids[key] = key;
    });


  return (
    <Grid container direction="column" alignItems="center" justify="center" sx={useStyles.root}>
      <Grid item xs={12}>
        {welcomeMessage}
      </Grid>
      <Grid item xs={12} container sx={!isAdmin ? hiddenSX : {}}>
        <TaskReviewPane
          groupID={groupID}>
        </TaskReviewPane>
      </Grid>
      <Grid sx={useStyles.characterCardGrid} item container spacing={6} direction ="row" xs={12} justify="center" alignItems="center"  justifyContent="center" >
        {names.sort((a,b)=>b.length - a.length).map((name, index)=>{
          return (
            <Grid item key={index} xs={12} md={4} sx={useStyles.ImageGridItem}>
                <CharacterCard
                  name={name}
                  displayName={memberData[name].displayName}
                  uid={uids[name]}
                  imgSrc={avatars[name]}
                  tasks={tasks}
                  groupID={groupID}
                  userData={memberData[name]}
                  choresData={choresData}
                  tasksData={tasksData}
                  statsData={memberData[name]["stats"]}
                  compact
                />
            </Grid>
          )
        })}
      </Grid>
        {/* <Grid item xs={12} md={7}>
        <Paper elevation={10} sx={useStyles.newsfeedPaper}>
          <NewsFeedComponent filter="all" title="Blackstone Castle News" groupID={groupID} />
        </Paper>
      </Grid> */}
      {/* <InProgressPane /> */}
      <Grid item xs={12} md={2} sx={useStyles.titleRow} container alignItems="center" justify="flex-start">
      </Grid>
   </Grid>

  )

}

export default DashboardPage;
