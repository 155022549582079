import React, { useState } from 'react';
import { capitalizeFirstLetter, getChoreDataOnCompletion, deleteChore, markChoreComplete, editTask, deleteTask, createRoom, roomNameExists, deleteRoomAndChores } from '../firebase/FirebaseDB';
import { ChoreFilter, flattenChoresMap } from '../util/ChoreDataUtils';
import LoadingPage from './LoadingPage';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

import { Grid, Button } from '@mui/material';
import { Menu, MenuItem } from '@mui/material';
import { LargeOldEnglishText } from '../components/OldEnglishTypography';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ExpandingChoresTable from '../components/ExpandingChoresTable';
import EditChoreDialog from '../components/dialogs/EditChoreDialog';
import EditTaskDialog from '../components/dialogs/EditTaskDialog';
import NewRoomDialog from '../components/dialogs/NewRoomDialog';
import { useFamilyDataListener } from '../firebase/firebase';
import { ExpandingTaskTable } from '../components/AdvenchoreTasksPane';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

const compStyles = {
  background: {
    minHeight: '90vh',
    minWidth: '100vw',
    // backgroundImage: 'url(../images/advenchore/backgrounds/inside.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    margin: 0,
    padding: 0,
    backgroundAttachment: 'fixed',
  },
  gridItems: {
    width: '95vw',
    margin: 'auto',
  },
  list: {
    width: '100%',
    margin: 20,
    display: 'flex',
    flexWrap: 'wrap',
  },
  logo: {
    height: 40,
  },
  formControl: {
    minWidth: 120,
    marginTop: 10,
  },
  assignedTo: {
    marginTop: 25,
  },
  reward: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  dialog: {
    minWidth: 200,
  },
  throneRow: {
    margin: 'auto',
  },
  titleRow: {
    height: 50,
  },
  taskReviewRow: {
    width: '100%',
  }
}

const familyChoreColumns = [
    { title: 'Description', field: 'description' },
    { title: 'Last Done', field: 'lastString' },
    { title: 'Due Date', field: 'dueString' },
    ];

const ThroneRoomPage = (props) => {
  const [showAddChore, setShowAddChore] = useState(false);
  const [showAddRoom, setShowAddRoom] = useState(false);
  const [choresFilter, setChoresFilter] = useState("all");
  const [filterOptionsText, setFilterOptionsText] = useState("All Chores");
  const [addAnchorElement, setAddAnchorElement] = useState(null);
  const [visAnchorElement, setVisAnchorElement] = useState(null);
  const [tab, setTab] = useState(0);
  const [showEditTask, setShowEditTask] = useState(false);
  const [task, setTask] = useState({});

  // const [play] = useSound("/sounds/success.mp3");

  const { enqueueSnackbar } = useSnackbar();

  const { groupID } = useParams();
  
  const newFamilyData = useFamilyDataListener(groupID);
  // const newFamilyData = useNewFamilyData(groupID);

  const loading = Object.keys(newFamilyData).length === 0;
  let choresData = loading ? {} : newFamilyData["data"]["chores"];
  let familyData = loading ? {} : newFamilyData["data"]["members"];
  let roomData = loading ? {} : newFamilyData["data"]["rooms"];
  let tasksData = loading ? {} : newFamilyData["data"]["tasks"];
  let flatChores = flattenChoresMap(choresData);

  const filter = new ChoreFilter(flatChores);

  let data = {};

  const avatars = {};
  let characters = [];
  Object.keys(familyData).forEach((member) => {
    characters.push(member);
    avatars[member] = `../../images/avatars/${familyData[member].avatar}Avatar.svg`;
  });

  const byAssignedArray = [...characters];
  byAssignedArray.push("unassigned");

  const onChoreComplete = (data) => {
    const newData = getChoreDataOnCompletion(data);
    Object.keys(newData).forEach((key) => {
      flatChores[data.id][key] = newData[key];
    });
    markChoreComplete(flatChores[data.id], groupID);
    // refresh();
    // play();
  }

  const onChoreAdded = (data) => {
    enqueueSnackbar(`New Chore created: ${data.description}`, {
        variant: 'success',
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },
    });
  }

  const onChoreEdited = (data) => {
    if (data.id === undefined) {
      return;
    }
    
    Object.keys(data).forEach((key) => {
      flatChores[data.id][key] = data[key];
    });
  }

  const onReassign = (choreID, newAssignedTo) => {
    flatChores[choreID]["assignedTo"] = newAssignedTo;
    // processChores();

  }

  const onChoreDeleted = (choreID) => {
    delete flatChores[choreID];
    deleteChore(choreID, groupID);
    // setIgnoreList([choreID, ...ignoreList]);
  }

  const hideVisibilityOptions = (filter, filterOptionsText) => {
    setChoresFilter(filter);
    setFilterOptionsText(filterOptionsText);
    setVisAnchorElement(null);
  }

  const onAddChoreSelected = () => {
    setShowAddChore(true);
    setAddAnchorElement(null);
  }

  const onAddRoomSelected =() => {
    setShowAddRoom(true);
    setAddAnchorElement(null);
  }

  const onAddTaskSelected = () => {
    setShowEditTask(true);
    setAddAnchorElement(null);
  }

  const onCloseAddRoomDialog = async(description, chores) => {
    if (!description) {
      enqueueSnackbar(`Room name must not be blank`, {
          variant: 'error',
          anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
          },
      });
      return;
    }


    const roomExists = await roomNameExists(groupID, description, chores);

    if (roomExists) {
      enqueueSnackbar(`Room name already exists`, {
          variant: 'error',
          anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
          },
      });
      return;
    }
    setShowAddRoom(false);
    createRoom(groupID, description, chores);
    enqueueSnackbar(`New Room ${description} created with ${chores.length} new chores`, {
        variant: 'success',
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },
    });
  };  


  const displayTypes = ["room", "assigned","frequency", "tasks" ];  //this needs to be the same order as the tabs order

  const showRoomPane = !loading && displayTypes[tab] === 'room';
  const showAssignedPane = !loading && displayTypes[tab] === 'assigned';
  // const showOverduePane = !loading && displayTypes[tab] === 'overdue';
  const showTasksPane = !loading && displayTypes[tab] === 'tasks';
  const showFrequencyPane = !loading && displayTypes[tab] === 'frequency';

  const displayIcon = (
    <Grid item container justify="center" alignItems="center" xs={12} sx={{}}>
      <Grid item container direction="row" xs spacing={2} sx={{}}>
        <Grid item sx={showTasksPane ? {display: {xs: "None"}} : {}}>
          <Button variant="contained" color="primary" onClick={event=>setVisAnchorElement(event.currentTarget)} aria-controls="visibility-menu" aria-haspopup="true">
            {filterOptionsText}
          </Button>
        </Grid>
      </Grid>
      <Grid item sx={compStyles.actionButton}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={event=>setAddAnchorElement(event.currentTarget)}
          aria-controls="visibility-menu"
          aria-haspopup="true"
          sx={compStyles.button}
          startIcon={<PostAddIcon />}
        >
          Add
        </Button>
      </Grid>
      <Menu
        id="add-menu"
        anchorEl={addAnchorElement}
        keepMounted
        open={Boolean(addAnchorElement )}
        onClose={() => setAddAnchorElement(null)}
      >
        <MenuItem onClick={onAddChoreSelected}>Add Chore</MenuItem>
        <MenuItem onClick={onAddRoomSelected}>Add Room</MenuItem>
        <MenuItem onClick={onAddTaskSelected}>Add Task</MenuItem>
      </Menu>
      <Menu
        id="visibility-menu"
        anchorEl={visAnchorElement}
        keepMounted
        open={Boolean(visAnchorElement)}
        onClose={() => hideVisibilityOptions('due', 'Chores Due')}
      >
        <MenuItem onClick={() => hideVisibilityOptions('all', 'All Chores')}>All</MenuItem>
        <MenuItem onClick={() => hideVisibilityOptions('due', 'Chores Due')}>Due</MenuItem>
        <MenuItem onClick={() => hideVisibilityOptions('overdue', 'Overdue Chores')}>Overdue</MenuItem>
      </Menu>
    </Grid>
  );

  const addChoreDialog = groupID ? (
    <EditChoreDialog
      open={showAddChore}
      onClose={()=>setShowAddChore(false)}
      choreData={{}}
      characters={characters}
      onChoreEdited={onChoreEdited}
      memberData={familyData}
      onChoreAdded={onChoreAdded}
      rooms={roomData}
      groupID={groupID}
    />
  ) : (<> </>);

  
  const onTaskEdited = (taskID, oldCategory, newCategory, description, qty) => {
    editTask(groupID, taskID, oldCategory, newCategory, description, qty)
  }

  const onTaskDeleted = (groupID, taskID, category) => {
    deleteTask(groupID, taskID, category);
  }

  const editTaskDialog = groupID ? (
    <EditTaskDialog
      open={showEditTask}
      onClose={()=>setShowEditTask(false)}
      groupID={groupID}
      onTaskEdited={onTaskEdited}
      onTaskDeleted={onTaskDeleted}
      tasksData={task}
      categories={Object.keys(tasksData)}
    />
  ) : (<></>);

  const newRoomDialog = groupID ? (
    <NewRoomDialog
      open={showAddRoom}
      onClose={onCloseAddRoomDialog}
      onCancel={()=>setShowAddRoom(false)}
      choreData={{}}
      characters={characters}
      groupID={groupID}
    />
  ) : (<> </>);

  const onTaskSelect = (category, taskID) => {
    setShowEditTask(true);
    tasksData[category][taskID]["taskID"] = taskID;
    setTask(tasksData[category][taskID]);
  }

  const onRoomDelete = (roomID) => {
    deleteRoomAndChores(groupID, roomID);
  }

  return (
    <div sx={{padding: 5}} >
      <Grid container direction="row" spacing={1} justify="center" sx={{padding: 2}}>
        <Grid item xs = {12} sx={{}}>
          <LargeOldEnglishText text={"Throne Room"} />
        </Grid>
        {showAddChore && addChoreDialog}
        {showEditTask && editTaskDialog}
        {showAddRoom && newRoomDialog}
        {loading && <LoadingPage />}

        <Grid item xs={12} sx={{}}>          
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tab} variant="fullWidth" onChange={(event, newValue)=>setTab(newValue)} aria-label="basic tabs example">
              <Tab label="By Room" />
              <Tab label="By Assigned" />
              <Tab label="By Frequency" />
              <Tab label="Tasks" />
            </Tabs>
          </Box>
        </Grid>
        {(showRoomPane || showAssignedPane || showTasksPane || showFrequencyPane) && displayIcon}

        <Grid item container spacing={1} direction="row" xs={12} sx={showFrequencyPane ? {} : {display: {xs: "None"}}}>
          {/* BY FREQUENCY */}
          {Object.keys(choresData).sort().map(function(frequency, index) {
            if (data.length === 0) {
              return null;
            }
            return (
              <Grid item xs={12} md={6} lg={4} key={index} elevation={10}>
                <ExpandingChoresTable
                  panelHeading={capitalizeFirstLetter(frequency)}
                  title={capitalizeFirstLetter(frequency)}
                  data={filter.filterChores(choresFilter === "due", null, frequency, null, false)}
                  columns={familyChoreColumns}
                  groupID={groupID}
                  characters={characters}
                  rooms={roomData}
                  familyData={newFamilyData}
                  onChoreComplete={onChoreComplete}
                  onChoreEdited={onChoreEdited}
                  onChoreDeleted={onChoreDeleted}
                  showAdmin
                  onChoreAdded={onChoreAdded}
                  onReassign={onReassign}
                  avatars={avatars}
                />
              </Grid>
            );
          })}
        </Grid>


        <Grid item container spacing={1} direction="row" xs={12} sx={showRoomPane ? {} : {display: {xs: "None"}}}>
          {/* BY ROOM */}
          {Object.keys(roomData).sort().map(function(room, index) {
            if (data.length === 0) {
              return null;
            }
            return (
              <Grid item xs={12} md={6} lg={4} key={index} elevation={10}>
                <ExpandingChoresTable
                  panelHeading={roomData[room].displayName}
                  title={roomData[room].displayName}
                  data={filter.filterChores(choresFilter === "due", null, null, room, false)}
                  columns={familyChoreColumns}
                  groupID={groupID}
                  characters={characters}
                  rooms={roomData}
                  familyData={newFamilyData}
                  onChoreComplete={onChoreComplete}
                  onChoreEdited={onChoreEdited}
                  onChoreDeleted={onChoreDeleted}
                  onDelete={()=>onRoomDelete(room)}
                  showAdmin
                  onChoreAdded={onChoreAdded}
                  onReassign={onReassign}
                  avatars={avatars}
                />
              </Grid>
            );
          })}
        </Grid>

        <Grid item container direction="row" spacing={1} xs={12} sx={showAssignedPane ? {} : {display: {xs: "None"}}}>
          {/* BY ASSIGNED */}
          {Object.keys(byAssignedArray).sort().map(function(key, index) {
          const name = byAssignedArray[key].toLowerCase();
          const displayName = Object.keys(familyData).includes(name) ? familyData[name].displayName : name;

          return (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <ExpandingChoresTable
                panelHeading={capitalizeFirstLetter(displayName)}
                title={capitalizeFirstLetter(displayName)}
                data={filter.filterChores(choresFilter ==="due", name, null, null, false)}
                groupID={groupID}
                characters={characters}
                rooms={roomData}
                familyData={newFamilyData}
                onChoreComplete={onChoreComplete}
                onChoreEdited={onChoreEdited}
                onChoreDeleted={onChoreDeleted}
                onReassign={onReassign}
                onChoreAdded={onChoreAdded}
                avatars={avatars}
                showAdmin
                columns={[
                    { title: 'Room', field: 'roomName'},
                    { title: 'Description', field: 'description' },
                    { title: 'Due Date', field: 'dueString' },
                    ]}
                />
              </Grid>
              )
            })
          }
        </Grid>

        
        <Grid item container direction="row" spacing={1} xs={12} sx={showTasksPane ? {} : {display: {xs: "None"}}}>
          {Object.keys(tasksData).sort().map((category,index)=>{
            return (
            <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
              <ExpandingTaskTable 
                key={index}
                onClickRow={onTaskSelect}
                panelHeading={category}
                tasks={tasksData[category]}
              />
            </Grid>
            );
          })}
        </Grid>
        

        {/* BY OVERDUE */}
        {/* {!loading && (displayType === 'overdue') && (
            <Grid item xs={12} md={6} lg={4}>
              <ExpandingChoresTable
                panelHeading={"Most Overdue Chores"}
                title={"Most Overdue Chores"}
                data={filterMostOverdue(data[choresFilter], 10)}
                groupID={groupID}
                onChoreComplete={onChoreComplete}
                onChoreEdited={onChoreEdited}
                  familyData={newFamilyData}
                onChoreDeleted={onChoreDeleted}
                onChoreAdded={onChoreAdded}
                onReassign={onReassign}
                rooms={roomData}
                characters={characters}
                avatars={avatars}
                columns={[
                    { title: 'Room', field: 'roomName' },
                    { title: 'Description', field: 'description' },
                    { title: 'Days Overdue', field: 'daysOverDue', customSort: (a, b) => parseInt(a.daysOverDue) - parseInt(b.daysOverDue)}
                    ]}
              />
            </Grid>
        )} */}


      </Grid>
    </div>
  );
}

export default ThroneRoomPage;
