import {React} from 'react'
import { Grid, Paper } from "@mui/material";
import {capitalizeFirstLetter, submitTaskRequest} from '../firebase/FirebaseDB';
import { MediumOldEnglishText } from './OldEnglishTypography';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import {Badge, Typography, Table, TableRow, TableCell, TableBody, TableContainer} from '@mui/material/';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import swal from 'sweetalert2';


export const ExpandingTaskTable = ({tasks, panelHeading, onClickRow}) => {

  if (!tasks){
    return <></>
  }
  const numTasks = Object.keys(tasks).length;

  Object.keys(tasks).forEach(key=>{
    tasks[key]["id"]=key;
  });

  const tasksList = Object.values(tasks);
  tasksList.sort(function(a, b){
    let x = a.description.toLowerCase();
    let y = b.description.toLowerCase();
    if (x < y) {return -1;}
    if (x > y) {return 1;}
    return 0;
  });

  return (
    <MuiAccordion>
       <MuiAccordionSummary
         expandIcon={<ExpandMoreIcon />}
         aria-controls="panel1a-content"
         id="panel1a-header"
         sx={{backgroundColor: "#9db6fc"}}
       >
       <Badge badgeContent={numTasks} color="primary">
         <Typography sx={{}}>
          {capitalizeFirstLetter(panelHeading)}
         </Typography>
         </Badge>
       </MuiAccordionSummary>
       <MuiAccordionDetails sx={{padding: 0, margin: 0}}>
        <Grid container direction="column">
          <TableContainer component={Paper}>
            <Table sx={{ width: "100%" }} aria-label="simple table">
              <TableBody>
                  {tasksList.map((task, index)=>{
                    return (
                      <TableRow
                      onClick={() => onClickRow(panelHeading, task.id)}
                      key={index}>
                        <TableCell>{task.description}</TableCell>
                        </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
         </Grid>
       </MuiAccordionDetails>
     </MuiAccordion>
  )
}

export const AdvenchoreTasksPane = ({tasks, name, groupID }) => {
  if (!tasks) {
    return <div />
  }

  const onClickRow = async(category, index) => {
    const { value: text } = await swal.fire({
      input: 'textarea',
      inputLabel: `Request Reward for Task\n${tasks[category][index].description}`,
      inputPlaceholder: 'Explain how you completed this task...',
      confirmButtonText: 'Submit',
      inputAttributes: {
        'aria-label': 'Type your message here'
      },
      showCancelButton: true
    })
    
    if (text) {
      submitTaskRequest(groupID, name, category, index, text);
      swal.fire('Submitted!', '', 'success')
    }
    
  }

  if (!tasks) {
    return <></>
  }

  return (
    <Grid container direction="column" sx={{
      flexGrow: 1,
      minHeight: 50,
      padding: 1,
    }}>

      <MediumOldEnglishText text={"Advenchore Tasks"} />
      {Object.keys(tasks).sort().map((category,index)=>{

        return (
          <ExpandingTaskTable 
            key={index}
            onClickRow={onClickRow}
            panelHeading={category}
            tasks={tasks[category]}
          />
        );
      })}
      

      {/* {taskList && taskList.map((task, idx) => {
        return (
          <Grid key={idx} item styles= {{ width: '100%'}}>
           <TaskRow task={task} callback={()=>props.taskCallback(task.id, task.description)} />
         </Grid>);
      })} */}
      {/* {!taskList && (<Grid item xs>No tasks defined yet for {capitalizeFirstLetter(props.category)}.</Grid>)} */}
    </Grid>
  );
}