import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, List, ListItem, ListItemAvatar, ListItemText, DialogTitle, Dialog } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { blue } from '@mui/material/colors';
import { capitalizeFirstLetter, reassignChore } from '../../firebase/FirebaseDB';

const useStyles = {
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
};

export function ReassignDialog(props) {
  const { onClose, selectedValue, open, memberData } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (assignedTo) => {
    onClose(assignedTo);
    reassignChore(props.choreData.id, assignedTo, props.choreData.description, props.groupID);
    props.onReassign(props.choreData.id, assignedTo);

  };



  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Reassign Chore</DialogTitle>
      <List>
        {props.characters.map((character) => {
          const displayName = Object.keys(memberData).includes(character) ? memberData[character].displayName : character;
          return (
          <ListItem button onClick={() => handleListItemClick(character)} key={character}>
            <ListItemAvatar>
              <Avatar alt="avatar" src={props.avatars ? props.avatars[character] : ""} sx={useStyles.avatar}>
                <PersonIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={capitalizeFirstLetter(displayName)} />
          </ListItem>
         );
        }
        )}
      </List>
    </Dialog>
  );
}

ReassignDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string,
  groupID: PropTypes.string.isRequired,
  characters: PropTypes.array.isRequired,
  avatars: PropTypes.object.isRequired,
};
