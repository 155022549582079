import React, { useState } from 'react';
import { Box, TextField } from '@mui/material';
import styled from '@emotion/styled';

const ChatContainer = styled(Box)`
  background: black;
  min-height: 100vh;
  padding: 16px;
`;

const Message = styled.div`
  color: white;
  background-color: ${({ isUser }) => (isUser ? '#2196F3' : '#9C27B0')};
  display: inline-block;
  border-radius: 16px;
  margin: 4px 0;
  padding: 8px 16px;
  align-self: ${({ isUser }) => (isUser ? 'flex-end' : 'flex-start')};
`;

function StoriesPage() {
  const [messages, setMessages] = useState([]);
  const [userMessage, setUserMessage] = useState('');

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      setMessages([...messages, { text: userMessage, isUser: true }]);
      setUserMessage('');
    }
  };

  if(false) {
  return (<div>100</div>);
  }

  return (
    <ChatContainer display="flex" flexDirection="column">
      <Box flexGrow={1}>
        {messages.map(({ text, isUser }, index) => (
          <Message key={index} isUser={isUser}>
            {text}
          </Message>
        ))}
      </Box>
      <TextField
        value={userMessage}
        onChange={(e) => setUserMessage(e.target.value)}
        onKeyPress={handleKeyPress}
        fullWidth
        autoComplete="off"
        placeholder="Type your message..."
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          '& .MuiInputBase-input': {
            color: 'white',
          },
        }}
      />
    </ChatContainer>
  );
}

export default StoriesPage;