import React from 'react';
// import ReactDOM from 'react-dom';
import './index.css';
// import App from './App';
import App from './pages/App';
import reportWebVitals from './reportWebVitals';


import { render } from 'react-dom';
const container = document.getElementById('root');
render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
, container);

reportWebVitals();
