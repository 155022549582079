import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

const useStyles = {
  root: {
    height: '100vh',
    width: '100vw',
  }
};

export default function LoadingPage() {

  return (
    <Grid container justify="center" alignItems="center" sx={useStyles.root}>
      <CircularProgress size={200} />
    </Grid>
  );
}
