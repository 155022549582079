import { urgencyLevel, getChoreUrgency, getDisplayColors, getFreqColor } from '../objects/ChoreUrgency';
import { freqToDays } from './Constants';
import moment from 'moment';

const dateFormat = "MMMM D";

// takes in an object with key=choreID and value=choreData, and returns only chores specified by filter
// returns an object with key=choreID and value=choreData
export class ChoreFilter {
  constructor(choreObj) {
    this.choreObj = JSON.parse(JSON.stringify(choreObj));
  }

  //for unassigned chores, pass nameFilter = "" or "unassigned"
  filterChores(onlyDue, nameFilter, freqFilter, roomFilter, noLocked) {
    let results = {};
    if (nameFilter === 'unassigned') {
      nameFilter = "";
    }

    Object.keys(this.choreObj).forEach((key)=>{
      let chore = this.choreObj[key];
      const urgency = getChoreUrgency(moment(chore.dueDate), moment(chore.urgentDue));
      if (noLocked && chore.assignedLock) {
        return;
      }
      if (onlyDue && urgency === urgencyLevel.DONE) {
        return;
      }
      if (nameFilter !== null && chore.assignedTo !== nameFilter) {
        return;
      }
      if (freqFilter !== null && chore.frequency !== freqFilter) {
        return;
      }
      if (roomFilter !== null && chore.room !== roomFilter) {
        return;
      }
      chore.id = key;
      results[key] = chore;
    });    
    return results;
  }
}

export function addChoreStrings(choreObj, roomData) {
  const lastString = choreObj.lastCompleted ? moment(choreObj.lastCompleted).format(dateFormat) : "N/A";
  const dueString = moment(choreObj.dueDate).format(dateFormat);
  choreObj["lastString"] = lastString;
  choreObj["dueString"] = dueString;
  choreObj["roomName"] = roomData[choreObj["room"]].displayName;
  return choreObj;
}


export default class ChoreDataUtils {
  // choresList is an array of chores
  static getChoreCounts(choresList) {
    let counts = {};
    counts[urgencyLevel.DONE] = 0;
    counts[urgencyLevel.DUE] = 0;
    counts[urgencyLevel.PAST_DUE] = 0;
    counts[urgencyLevel.URGENT_DUE] = 0;

    choresList.forEach((chore)=>{
      const due = chore["dueDate"] ? moment(chore["dueDate"]) : this.getDueDate(chore.lastCompleted, chore.frequency);
      const urgentDue = chore["urgentDue"] ? moment(chore["urgentDue"]) : this.getUrgentDueDate(chore.lastCompleted, chore.frequency);
      const level = getChoreUrgency(due, urgentDue);
      counts[level] = counts[level] + 1;
    })
    return counts;
  }

  static dueOrPastDue(dateTimestamp) {
    const now = moment();
    const due = moment(dateTimestamp);
    return due.isSameOrBefore(now, 'day');
  }

  static getDueDate(lastTimestamp, freqString) {
    const freq = freqToDays[freqString] || 1;
    const lastDate = moment(lastTimestamp ||  0);
    return lastDate.add(freq, 'd');
  }

  static getUrgentDueDate(dueTimestamp, freqString) {
    return this.getDueDate(this.getDueDate(dueTimestamp, freqString), freqString);
  }

  static getSxFromChoreData(choreData) {
    return getDisplayColors(moment(choreData.dueDate), moment(choreData.urgentDue));
  }

  static getStyleForChoreTable(choreData, colIdx) {
    const statusColor = getDisplayColors(moment(choreData.dueDate), moment(choreData.urgentDue));
    const freqColor = getFreqColor(choreData.frequency);
    if (colIdx !== 2) {
      return {"background": statusColor.backgroundColor};
    }
    return {"background": statusColor.backgroundColor};
    // return {"background": `linear-gradient(70deg, ${freqColor} 80%, ${statusColor.backgroundColor} 80%)`}; //TODO: work on this design
  }

  // BY ID
  // BY member
  // by due date
  // by frequency
  static getProcessedChoreData(choresData, roomData) {
    if (choresData === undefined) {
      return {};
    }
    
    let byID = {};
    let byMember = {};
    let byFreq = {};

    for (let i = 0; i < choresData.length; i++) {
      let chore = choresData[i];
      const freq = chore["frequency"];
      const member = chore["assignedTo"];
      const id = chore["id"];

      chore["roomName"] = roomData && roomData[chore["room"]] ? roomData[chore["room"]].displayName : "";
      
      if (!Object.keys(byMember).includes(member)) {
        byMember[member] = {};
      }      
      if (!Object.keys(byFreq).includes(freq)) {
        byFreq[freq] = {};
      }    
      if (!Object.keys(byID).includes(freq)) {
        byID[id] = {};
      }
      byMember[member][id] = chore;
      byID[id] = chore;
      byFreq[freq][id] = chore;
    }

    let data = {};
    data.due = filterChoresByDue(choresData);
    data.overdue = filterChoresByOverDue(choresData);
    data["all"] = choresData;
    data["byID"] = byID;
    data["byFreq"] = byFreq;
    data["byMember"] = byMember;
    return data;
  }
}

// expecting one level, freq, to flatten
export function flattenChoresMap(choresMap) {
  let result = {};
  Object.keys(choresMap).forEach((freq)=>{
    Object.keys(choresMap[freq]).forEach((key)=>{
      result[key] = choresMap[freq][key];
    })
  });
  return result;
}

function filterChoresByDue(choresList) {
  return choresList.filter(chore => ChoreDataUtils.dueOrPastDue(ChoreDataUtils.getDueDate(chore.lastCompleted, chore.frequency)));
}

function filterChoresByOverDue(choresList) {
  return choresList.filter(chore => {
    const alert = ChoreDataUtils.getUrgentDueDate(ChoreDataUtils.getDueDate(chore.lastCompleted, chore.frequency), chore.frequency);
    return alert.isSameOrBefore(moment(), 'day');
    });
}