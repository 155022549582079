import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {Avatar, Grid, Grow, Paper, Popper, MenuItem, MenuList, ClickAwayListener} from '@mui/material';
import { createFeedback, FirebaseGroupManager } from '../../firebase/FirebaseDB';
import { Firebase, auth } from '../../firebase/firebase';
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { withSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import swal from 'sweetalert2';

const useStyles = {
  root: {
    display: 'flex',
    margin: 0,
    padding: 0,
  },
  avatarGrid: {
  margin: 0,
  padding: 0,
  },
};

const MenuItemWithHistory = (props) => {

  const handleClick = (event) => {
  }
  return (
    <MenuItem onClick={handleClick}>My Page</MenuItem>
  );
}

export default function UserComponent (props) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [anchorElement, setAnchorElement] = useState(null);
  const [location, setLocation] = useState(null);

  const userUID = props.userUID;
  // const firstName = localStorage.getItem("firstName") ? localStorage.getItem("firstName".toLowerCase()) : "";
  const mgr = null;

  const onSettings = () => {
    console.log('settings');

  }

  const submitFeedback = async()=> {
    swal.fire({
      title: 'Website Feedback',
      input: 'text',
      inputLabel: 'Enter your comments!',
      inputValue: "",
      confirmButtonText: "Submit",
      inputValidator: (value) => {
        if (!value) {
          return 'You need to write something!'
        }
      }
    }).then((data)=>{
      createFeedback(data.value, props.groupID);
    }).then(() =>{
      swal.fire('Feedback submitted!  Thank you!', '', 'success')
    });
  }

  const login = () => {
    navigate("/signIn");
  }

  const handleToggle = (event) => {
    setOpen(!open);
    setAnchorElement(event.currentTarget);
  }

  // const goToMyPage = () => {
  //   navigate(`/${props.groupID}/profile/${firstName}`);
  // }

  const logout = (event) => {
    setOpen(false);
    let self = this;

    const auth = getAuth();
    signOut(auth).then(() => {
      localStorage.removeItem("familyData");
      enqueueSnackbar('You have been logged out', {
          variant: 'success',
          anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
          },
      });
      navigate("/signIn");
    }).catch((error) => {
      // An error happened.
    });
  }

  const handleClose = (event) => {
    setOpen(false);
  }

  const handleListKeyDown = (event) => {
  } 

  let showingMyPage = true;
    let avatar = (<Avatar alt={"login"} onClick={handleToggle} />);

    let popperMenu = (
      <MenuList autoFocusItem={open}  id="menu-list-grow" onKeyDown={handleListKeyDown}>
        <MenuItem onClick={login}>Login</MenuItem>
      </MenuList>
    );

    if (props.user) {
      avatar = (
        <Avatar
          alt={props.user.displayName}
          src={props.avatar || props.user.photoURL}
          onClick={handleToggle} />
              // ref={"anchorRef"} />
      );
      popperMenu = (
        <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
          {!showingMyPage && (<MenuItemWithHistory link={`/advenchore/character/${props.user.firstName.toLowerCase()}`} />)}
          <MenuItem onClick={submitFeedback}>Submit Feedback</MenuItem>
          <MenuItem onClick={onSettings}>Settings</MenuItem>
          <MenuItem onClick={logout}>Logout</MenuItem>
        </MenuList>
      );
    }

  return (
    <Grid container
      direction="column"
      justify="flex-end"
      sx={useStyles.avatarGrid}
      alignItems="flex-end">
      <Grid item>
        {avatar}
          <Popper open={open} anchorEl={anchorElement } transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                  {popperMenu}
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
      </Grid>
      <Grid item>
      </Grid>
    </Grid>
  )

}
