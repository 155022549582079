import {React, useState} from 'react';
import PropTypes from 'prop-types';
import { Paper, Grid } from "@mui/material";
import ExpandingChoresTable from './ExpandingChoresTable';
import { MediumOldEnglishText } from './OldEnglishTypography';
import ChoreDataUtils, { ChoreFilter } from '../util/ChoreDataUtils';
import { deleteChore, completeChoreAndGiveReward, getChoreDataOnCompletion} from '../firebase/FirebaseDB';
import { useNavigate } from 'react-router-dom';

function filterChoresByRoom(choresList, roomFilter) {
  return choresList.filter(chore => chore.room === roomFilter);
}

export const AssignedChoresPane = (props) => {
  const { roomData, groupID, characters, avatars, chores, name, onRefresh, familyData} = props;
  const [filteredChoreIDs, setFilteredChoreIDs] = useState([]);  //list of chore ids that were marked as complete or reassigned or deleted
  const navigate = useNavigate();

  let expansionTables = <></>;

  //reporting only, DB function handled in the ReactionDialog
  const onReassign = (choreID, newAssignedTo) => {
    setFilteredChoreIDs(filteredChoreIDs => [...filteredChoreIDs, choreID]);
  }

  const onChoreDeleted = (choreID) => {
    deleteChore(choreID, groupID);
    setFilteredChoreIDs(filteredChoreIDs => [...filteredChoreIDs, choreID]);
  }

  const onChoreComplete = (data) => {
    const newData = getChoreDataOnCompletion(data);
    Object.keys(newData).forEach((k)=>{
      data[k] = newData[k];
    })
    completeChoreAndGiveReward(groupID, name, data);
    setFilteredChoreIDs(filteredChoreIDs => [...filteredChoreIDs, data.id]);
  }

  const onChoreEdited = (data) => {
    if (data.id === undefined) {
      onRefresh();
      return;
    }
    
    Object.keys(data).forEach((key) => {
      chores[data.id][key] = data[key];
    }); 
    onRefresh();
  }

  if (roomData) {
    expansionTables = (
      Object.keys(roomData).sort().map(function(key, index) {
        const filter = new ChoreFilter(chores);
        const choreList = filter.filterChores(true, props.name, null, key, false);
        if (Object.keys(choreList).length === 0) {
          return null;
        }
        if (!roomData[key]) {
          return null;
        }
        for (let i = 0; i < filteredChoreIDs.length; i++) {
          const id = filteredChoreIDs[i];
          delete choreList[id];
        }
        return (
          <Grid item xs={12} key={index}>
            <ExpandingChoresTable
              panelHeading={roomData[key].displayName}
              title={roomData[key].displayName}
              data={choreList}
              groupID={groupID}
              characters={characters}
              avatars={avatars}
              familyData={familyData}
              rooms={roomData}
              onChoreComplete={onChoreComplete}
              onChoreEdited={onChoreEdited}
              onChoreDeleted={onChoreDeleted}
              onReassign={onReassign}
              showAdminFunctions={false}
              columns={[
                  { title: 'Description', field: 'description' },
                  { title: 'Last Done', field: 'lastString' },
                  { title: 'Due Date', field: 'dueString' },
                  ]}
            />
          </Grid>
        );
      }
    ));
  }

  return (
     <Paper elevation={5}>
      <Grid item xs={12} container justifyContent="center" alignItems="center" style= {{ padding: 5, bgcolor: "white" }}>
        <MediumOldEnglishText text="Assigned Chores" sx={{bgcolor: "white"}} />
          {expansionTables}
        {Object.keys(props.chores).length===0 && (<Grid item container justifyContent="center" xs={12}><span>No Chores left today, great job!</span></Grid>)}
      </Grid>
     </Paper>
  );
}

AssignedChoresPane.propTypes = {
 classes: PropTypes.object,
 groupID: PropTypes.string.isRequired,
 characters: PropTypes.array.isRequired,
 avatars: PropTypes.object.isRequired,
};
