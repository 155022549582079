import {React, useState} from 'react';
import { Grid, Paper } from "@mui/material";
import { TaskRow } from './ChoreComponents'
import {capitalizeFirstLetter   , processTaskRequest } from '../firebase/FirebaseDB';
import { MediumOldEnglishText } from './OldEnglishTypography';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import {Badge, Typography, Table, TableHead, TableRow, TableCell, TableBody, TableContainer} from '@mui/material/';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import swal from 'sweetalert2';
import { useFirebaseAuthentication, useNewFamilyData, useRequestsData } from '../firebase/firebase';
import moment from 'moment';

const dateFormat = "MMMM D";

export const TaskReviewPane = (props) => {
    const [ processedRequests, setProcessedRequests] = useState([]);

    const newFamilyData = useNewFamilyData(props.groupID);
    const requestsData = useRequestsData(props.groupID);
    const memberData = newFamilyData["data"] ? newFamilyData["data"]["members"] : {};
    const taskData = newFamilyData["data"] ? newFamilyData["data"]["tasks"] : {};

  const onClickRow = (request, id) => {
    swal.fire({
        title: 'Do you want to approve this reward request?',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Approve',
        denyButtonText: `Deny`,
      }).then((result) => {
        if (result.isConfirmed) {
          swal.fire('Approved!', '', 'success');
          const reward = taskData[request.category][request.index].qty;
          setProcessedRequests(processedRequests => [...processedRequests, id]);
          processTaskRequest(props.groupID, id, result.isConfirmed, request.name, reward, request.category);
        } else if (result.isDenied) {
          swal.fire('Request has been denied and deleted!', '', 'info');
          setProcessedRequests(processedRequests => [...processedRequests, id]);
          processTaskRequest(props.groupID, id, result.isConfirmed);
        }
      })
    
  }

  const ExpandingTaskReviewTable = (props) => {
    const {tasks} = props;

    if (!tasks){
      return <></>
    }
    const numTasks = Object.keys(tasks).filter(id=>!processedRequests.includes(id)).length;

    return (
      <MuiAccordion defaultExpanded={numTasks > 0}>
         <MuiAccordionSummary
           expandIcon={<ExpandMoreIcon />}
           aria-controls="panel1a-content"
           id="panel1a-header"
           sx={{backgroundColor: "#9db6fc"}}
         >
         <Badge badgeContent={numTasks} color="primary">
           <Typography sx={{}}>
            {capitalizeFirstLetter(props.panelHeading)}
           </Typography>
           </Badge>
         </MuiAccordionSummary>
         <MuiAccordionDetails sx={{padding: 0, margin: 0}}>
          <Grid container direction="column">
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Character</TableCell>
                        <TableCell>Category</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Reasoning</TableCell>
                        <TableCell>Date</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.keys(tasks).sort((a,b)=>a.name - b.name)
                    .filter(id => !processedRequests.includes(id))
                    .map((id, index)=>{
                        const request = tasks[id];
                        const description = taskData[request.category][request.index].description;
                        return (
                            <TableRow
                                onClick={() => props.onClickRow(request, id)}
                                key={index}>
                            <TableCell>{memberData[request.name].displayName}</TableCell>
                            <TableCell>{request.category}</TableCell>
                            <TableCell>{description}</TableCell>
                            <TableCell>{request.explanation}</TableCell>
                            <TableCell>{moment(request.timestamp).format(dateFormat)}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
           </Grid>
         </MuiAccordionDetails>
       </MuiAccordion>
    )
  }

  return (
    <Grid container direction="column" sx={{
      flexGrow: 1,
      minHeight: 50,
      padding: 1,
    }}>

      <MediumOldEnglishText text={"Advenchore Tasks"} />
      <ExpandingTaskReviewTable 
            onClickRow={onClickRow}
            panelHeading={"Tasks Review"}
            tasks={requestsData}
          />
    </Grid>
  );
}