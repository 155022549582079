import React, { useEffect, useRef } from 'react';
import LifeBar from './LifeBar';
import { Grid } from '@mui/material';


const blueMeter = {
    circle: {
        src: "../../images/levels/pngs/blue/meter_icon_holder_blue.png",
        height: 100,
        width: 100,
    },
    meter_r: {
        src: "../../images/levels/pngs/blue/meter_bar_holder_right_edge_blue.png",
        height: 50,
        width: 75,
    },
    meter_l: {
        src: "../../images/levels/pngs/blue/meter_bar_holder_left_edge_blue.png",
        height: 75,
        width: 25,
    },
    meter_center: {
        src: "../../images/levels/pngs/blue/meter_bar_holder_center-repeating_blue.png",
        height: 75,
        width: 75,
    },
    bar_l: {
        src: "../../images/levels/pngs/blue/meter_bar_left_edge_blue.png",
        height: 100,
        width: 100,
    },
    bar_r: {
        src: "../../images/levels/pngs/blue/meter_bar_right_edge_blue.png",
        height: 100,
        width: 100,
    },
    bar_center: {
        src: "../../images/levels/pngs/blue/meter_bar_center-repeating_blue.png",
        height: 100,
        width: 100,
    },
    bkg_left: {
        src: "../../images/levels/pngs/blue/meter_text_background_left_edge_blue.png",
        height: 100,
        width: 100,
    },
    bkg_right: {
        src: "../../images/levels/pngs/blue/meter_text_background_right_edge_blue.png",
        height: 100,
        width: 100,
    },
    bkg_center: {
        src: "../../images/levels/pngs/blue/meter_text_background_center_repeating_blue.png",
        height: 100,
        width: 100,
    },
    svg: {
        src: '../../images/icons/health.svg',
        height: 300,
        width: 300,

    },
}

function addImage(ctx, data, x, y) {
    const add = new Image();
    add.src = data.src;

    add.onload = () => ctx.drawImage(add, x, y, data.width, data.height);
}

export default function TestingPane() {
    const canvas = useRef(null);

    const width = 800;
    const height = 900;
    const buffer = 40;

    // useEffect(()=> {
    //     const ctx = canvas.current.getContext("2d");
    //     ctx.fillStyle = "green";
    //     ctx.fillRect(0, 0, width, height);

    //     // addImage(ctx, blueMeter.meter_center, 50, 0);
    //     // addImage(ctx, blueMeter.meter_center, 125, 0);
    //     // addImage(ctx, blueMeter.meter_r, 200, 0);
    //     addImage(ctx, blueMeter.svg, 0, 0);

    // }, []);

    return (
        <Grid container direction="column" sx={{}}>
            <h1>Meter Testing</h1>

            <Grid xs={1} item sx={{}}>
            <LifeBar
                current={60}
                type={"tv"}
                max={100} />
            <LifeBar
                current={0}
                type={"health"}
                max={100} />
            <LifeBar
                current={0}
                type={"power"}
                max={100} />
            <LifeBar
                current={92}
                type={"magic"}
                max={100} />
            <LifeBar
                current={92}
                type={"armor"}
                max={100} />
            <LifeBar
                current={75}
                type={"speed"}
                max={100} />
            <LifeBar
                current={45}
                type={"time"}
                max={100} />
            <LifeBar
                current={66}
                type={"xp"}
                max={100} />
            </Grid>


            {/* <div>
                <canvas 
                    ref={canvas}
                    width={width}
                    height={height + (2*buffer)}
                />
            </div> */}
        </Grid>
    )
}