import React from 'react'
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

const InProgressPane = (props) => {
  return (
   <Grid item container>
       <Grid item xs={12}>
           <img alt="Under Construction" src={"../../../images/inProgress.jpg"} />
       </Grid>
       <Grid item xs={12}>
         Your castle is still under construction, come back soon!
       </Grid>
   </Grid>
  );
}

export default InProgressPane;
