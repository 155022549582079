import React from 'react';
import { Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export const largeTheme = createTheme(
  {
    typography: {
      fontFamily: 'UnifrakturCook-Bold',
      fontSize: 40,
    }
});

export const  mediumTheme = createTheme(
  {
    typography: {
      fontFamily: 'UnifrakturCook-Bold',
      fontSize: 20,
    },
    palette: {
      primary: {
        main: '#000000',
      },
      secondary: {
        main: '#FFFFFF',
      }
    }
});

const OldEnglishText = (props) => {
  let color = "primary";
  if (props.useSecondaryColor) {
    color = "secondary";
  }
  return (
    <ThemeProvider theme={props.theme}>
      <Typography color={color}>
        {props.text}
      </Typography>
    </ThemeProvider>
  );
}

export const LargeOldEnglishText = (props) => (<OldEnglishText text={props.text} useSecondaryColor={props.useSecondaryColor} theme={largeTheme} />);
export const MediumOldEnglishText = (props) => (<OldEnglishText text={props.text} useSecondaryColor={props.useSecondaryColor} theme={mediumTheme} />);
