import React from 'react';
import PropTypes from 'prop-types';

import { Button, DialogContent, Select, DialogActions, InputLabel, MenuItem, DialogTitle, TextField, Dialog, Checkbox  } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import FirebaseStaticData from '../../firebase/FirebaseStaticData';
import { freqToDays } from '../../util/Constants';
import { capitalizeFirstLetter, createChore, updateChore} from '../../firebase/FirebaseDB';
import { useFamilyData, useRoomData, useChoresData } from '../../firebase/firebase';
import { useNavigate } from 'react-router-dom';


export default function EditChoreDialog(props) {
  const { onClose, open, choreData, onChoreEdited, onChoreAdded, groupID, memberData} = props;
  const [room, setRoom] = React.useState(choreData.room || "");
  const [description, setDescription] = React.useState(choreData.description || "");
  const [frequency, setFrequency] = React.useState(choreData.frequency || "");
  const [assignedTo, setAssignedTo] = React.useState(choreData.assignedTo || "");
  const [locked, setLocked] = React.useState(choreData.assignedLock || false);
  
  const navigate = useNavigate();

  const handleClose = () => {
    onClose();
  };

  const onUpdateChore = () => {
    let newChoreData = {};
    newChoreData.id = choreData.id;

    //if ID doesn't exist, then this is creating a new chore
    if (!newChoreData.id) {
      createChore(room, description, frequency, assignedTo, groupID, onChoreAdded);
    }
    if (choreData.lastCompleted) {
      newChoreData.lastCompleted = choreData.lastCompleted;
    }
    if (choreData.lastCompletedBy) {
      newChoreData.lastCompletedBy = choreData.lastCompletedBy;
    }
    newChoreData.room = room;
    newChoreData.description = description;
    newChoreData.frequency = frequency;
    newChoreData.assignedTo = assignedTo;
    newChoreData.assignedLock = locked;
    updateChore(newChoreData, groupID);
    onChoreEdited(newChoreData);
    onClose();
  }

  const title = Object.keys(choreData).length ? "Edit Chore" : "Add Chore";

  return (
    <Dialog onClose={handleClose} aria-labelledby="editDialogTitle" open={open}>
      <DialogTitle id="editDialogTitle">{title}</DialogTitle>
        <DialogContent>
          <InputLabel id="roomLabel">Room</InputLabel>
          <Select
            labelId="roomLabel"
            id="room"
            value={room}
            onChange={event => setRoom(event.target.value)}
          >
              {Object.keys(props.rooms).sort().map(room => (
              <MenuItem key={room} value={room}>
                {props.rooms[room].displayName}
              </MenuItem>
            ))}
          </Select>
          <br />
          <br />
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Chore Description"
            type="string"
            value={description}
            onChange={event => setDescription(event.target.value)}
            fullWidth
          />
          <br />
          <br />
          <InputLabel id="assignedToLabel">Assigned To</InputLabel>
          <Select
            labelId="assignedToLabel"
            id="assignedTo"
            value={assignedTo}
            onChange={event => setAssignedTo(event.target.value)}
          >
              {props.characters.sort((a,b)=>b.length-a.length).map(character => {
                const displayName = memberData[character].displayName;
                return (
              <MenuItem key={character} value={character}>
                {capitalizeFirstLetter(displayName)}
              </MenuItem>
              );
            }
            )}
          </Select>
          <Checkbox
            checked={locked}
            onChange={()=>setLocked(!locked)}
            icon={<LockOpenIcon />}
            checkedIcon={<LockIcon />}
          />
          <br />
          <br />
          <InputLabel id="freqLabel">Frequency</InputLabel>
          <Select
            labelId="freqLabel"
            id="freq"
            value={frequency}
            onChange={event => setFrequency(event.target.value)}
          >
              {Object.keys(freqToDays).map(freq => (
              <MenuItem key={freq} value={freq}>
                {capitalizeFirstLetter(freq)}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={onUpdateChore} color="primary" autoFocus>
            Save
          </Button>
        </DialogActions>
    </Dialog>
  );
}
