import React, {useEffect, useState} from 'react';
import { Grid } from '@mui/material';
import { parsePath } from 'react-router-dom';

const paths = [];

paths.push("m188.08 421.2 27.023 15.73s2.6888 1.4789 1.0755 2.9577c-1.6133 1.4789-5.3777 6.8565-6.8565 7.9321-1.4789 1.0755-18.15 13.579-24.872 14.116-6.7221 0.53777-24.065 1.21-30.653 0.13444-6.5876-1.0755-22.99-5.5121-28.502-10.621-5.5121-5.1088-17.881-14.116-19.091-16.94-1.21-2.8233-2.5544-6.4532-2.5544-6.4532s-1.0755-2.0166 0.40332-2.6888c1.4789-0.6722 2.6888-1.21 5.6466-1.8822 2.9577-0.67221 26.485-8.7387 26.485-8.7387s2.42-1.21 5.3777 0 2.2855 1.4789 3.6299 3.4955 1.6133 4.0332 5.6466 5.1088c4.0332 1.0755 5.1088 2.1511 9.6798 2.2855 4.571 0.13444 6.991 0.53777 10.218-0.26889 3.2266-0.80665 5.3777-1.21 7.3943-2.9577s3.4955-3.6299 4.7055-3.7644c1.21-0.13444 2.1511-0.13444 3.4955 0.53777s2.42 2.1511 2.42 2.1511-1.4789-0.26888-0.67221-0.13444z");
paths.push("m100.43 392.43 33.745 0.13444s1.21-1.0755 2.1511-0.40333c0.94109 0.67221 1.21 0.80666 1.4789 2.2855 0.26888 1.4789 0.40332 5.9154 0.40332 6.7221 0 0.80665 0.9411 2.2855-1.4789 2.6888-2.42 0.40333-36.703 9.2765-36.703 9.2765s-0.80665 0.94109-0.80665-2.5544-0.26888-5.2432-0.26888-7.9321c0-2.6888-0.13444-6.0499 0.26888-7.1254s0.53777-2.8233 1.21-3.0922z");
paths.push("m149.9 397.54s2.5544 1.7477 3.8988 2.9577 2.0166 1.3444 2.1511 4.3021c0.13444 2.9577 0.53776 5.9154 0 7.2598-0.53777 1.3444-0.26889 1.8822-1.4789 1.8822s-1.7478-0.80665-2.2855-1.6133c-0.53776-0.80665-1.4789-1.6133-2.0166-2.6888-0.53777-1.0755-2.0166-2.9577-2.2855-4.3021-0.26889-1.3444-1.0755-3.0922-1.0755-3.8988 0-0.80665 0-1.8822 0.53777-2.6888 0.53777-0.80665 1.7477-1.4789 1.7477-1.4789z");
paths.push("m171.01 404.53 5.6466-4.4366s2.2855-1.0755 2.5544 0.26889c0.26888 1.3444-1.4789 4.571-1.4789 4.571s-1.3444 7.2599-2.5544 8.4698c-1.21 1.21-2.8233 2.6888-4.0333 2.9577-1.21 0.26889-2.1511 0.80665-2.1511-1.8822s0.67221-7.7976 0.67221-7.7976z");
paths.push("m193.73 399.69 33.476 9.6798s2.42 1.3444 1.6133 3.8988c-0.80665 2.5544-3.2266 11.024-5.3777 13.041-2.1511 2.0166-1.7477 3.0922-2.9577 2.2855-1.21-0.80665-6.8565-4.0333-11.024-6.991-4.1677-2.9577-12.234-8.6043-14.654-8.8732-2.42-0.26888-5.1088-1.7477-5.3777-2.6888-0.26888-0.9411-0.94109-1.3444-0.13444-4.4366s-0.26889-4.571 1.3444-5.2432c1.6133-0.67221 3.0922-0.67221 3.0922-0.67221z");
paths.push("m105.81 362.85s10.755 0.13444 15.864 0.94109c5.1088 0.80665 6.991 1.7477 9.2765 1.8822 2.2855 0.13445 6.8565 0.53777 6.8565 0.53777s2.5544 0.26889 2.6888 2.1511c0.13444 1.8822-0.13445 4.1677-0.13445 6.7221s-1.8822 4.8399-1.8822 4.8399-2.42 1.6133-6.8565 1.3444c-4.4366-0.26889-4.9744-0.26889-9.8143-0.53777s-9.9487-0.26888-11.293-0.26888h-9.4109s-2.5544 0.53776-1.6133-3.6299c0.94109-4.1677 0.80665-6.3188 1.3444-8.201 0.53776-1.8822 0.13444-3.361 1.21-4.3021 1.0755-0.9411 3.7644-1.4789 3.7644-1.4789z");
paths.push("m153.13 372.4 5.6466 6.3188s0.80665 0.26889 0.80665 3.4955 0.13445 5.3777-1.0755 5.3777-4.1677-0.94109-5.2432-2.0166-3.4955-1.8822-4.1677-3.2266c-0.67221-1.3444-1.6133-2.6888-1.0755-4.9744 0.53777-2.2855 1.4789-4.3021 2.2855-4.7055 0.80665-0.40333 2.8233-0.26888 2.8233-0.26888z");
paths.push("m172.22 381.27s5.5121-2.8233 7.7976-3.2266c2.2855-0.40332 2.6888-0.13444 2.5544 1.8822-0.13445 2.0166-0.13445 3.8988-0.13445 3.8988s0.40333 1.6133-0.6722 2.1511c-1.0755 0.53777-3.0922 2.0166-4.571 2.2855-1.4789 0.26888-4.3021 1.6133-5.2432 0.80665-0.94109-0.80665-0.94109-3.0922-0.67221-4.0332 0.26889-0.9411 0.9411-3.7644 0.9411-3.7644z");
paths.push("m199.11 373.74s26.216 5.781 28.367 5.9154c2.1511 0.13445 4.3021 0.26889 4.9743 0.67221 0.67221 0.40333 2.0166-0.26888 1.7478 2.42-0.26889 2.6888-1.3444 7.9321-1.7478 9.5454-0.40332 1.6133-1.3444 3.0922-2.2855 3.7644-0.94109 0.67221-3.7644 0.80665-6.3188 0.26888s-17.881-4.9744-21.914-5.9154c-4.0333-0.94109-8.4698-1.3444-8.7387-2.6888-0.26888-1.3444-0.80665-3.361-0.40333-6.3188 0.40333-2.9577 0.26889-5.3777 0.67221-6.4532 0.40333-1.0755 0.80666-2.8233 2.42-2.5544 1.6133 0.26888 3.2266 1.3444 3.2266 1.3444z");
paths.push("m114.28 325.07s10.486 2.2855 13.31 2.2855c2.8233 0 2.0166-1.0755 4.4366-0.26889 2.42 0.80665 4.0333 0.67221 5.781 1.3444 1.7477 0.67221 2.6888 1.3444 4.3021 1.3444s2.5544 0.67221 2.42 4.4366c-0.13444 3.7644-1.0755 5.3777-1.0755 8.8732s-1.3444 10.083-1.3444 10.083l-37.375-1.8822s-1.21-1.8822 0.53777-5.1088c1.7477-3.2266 2.0166-5.6466 2.42-7.9321 0.40333-2.2855 0.94109-3.8988 1.8822-5.1088 0.94109-1.21 1.7477-3.2266 2.0166-4.1677 0.26889-0.9411 0.67221-4.0333 0.67221-4.0333z");
paths.push("m155.82 343.22 7.3943 7.5287s0.67221 0.40333 0.40333 3.4955-1.3444 8.201-1.3444 8.201 0.94109 1.8822-0.53777 1.7477c-1.4789-0.13444-4.9743-1.3444-5.6466-2.2855-0.67221-0.94109-3.0922-2.2855-3.361-2.8233-0.26888-0.53777-1.0755-1.8822-1.21-2.42-0.13445-0.53776-1.0755-3.8988-0.67221-5.9154 0.40332-2.0166 0.40332-4.7055 1.4789-5.9154 1.0755-1.21 2.42-1.7477 2.42-1.7477z");
paths.push("m174.91 352.9s2.1511-1.7477 3.4955-1.7477 3.2266-0.13444 4.0332-1.21c0.80666-1.0755 3.2266-2.2855 3.6299-2.2855 0.40333 0 0.80665 0.13444 0.80665 1.8822 0 1.7477-0.13444 4.0332-0.40332 5.781s-0.40333 3.8988-0.40333 4.9744c0 1.0755 2.2855 0.40333-0.53776 1.8822s-7.1254 3.6299-7.1254 3.6299l-2.42-0.13445s-1.3444-0.6722-1.4789-1.7477c-0.13444-1.0755-0.26889-2.42-0.13444-4.0332 0.13444-1.6133 0.53776-3.361 0.53776-3.8988v-3.0922z");
paths.push("m200.86 338.65 33.61 3.7644s2.6888-0.13444 2.6888 2.0166c0 2.1511-2.2855 22.317-2.2855 22.317s-0.40333 2.42-3.0922 1.7477c-2.6888-0.67221-32.938-5.781-32.938-5.781s-2.6888-0.26888-2.6888-2.2855 1.7477-20.032 1.7477-20.032-0.26888-2.2855 1.0755-2.2855 1.8822 0.53777 1.8822 0.53777z");
paths.push("m101.91 251.53 38.181-17.477 9.2765 19.225s0.67221 1.4789-0.53777 1.8822c-1.21 0.40332-35.762 20.973-35.762 20.973z");
paths.push("m168.86 221.28 1.3444 15.595-12.1 10.621-7.1254-13.848s-0.80665-2.1511 0.67221-2.6888c1.4789-0.53777 15.595-10.083 15.595-10.083z");
paths.push("m188.62 223.97 11.696 9.142s3.4955 0.13444 2.42 5.5121-2.0166 11.024-2.0166 11.024-0.26888 3.2266-4.7055-0.13444-14.385-11.159-14.385-11.159-2.6888-1.0755-2.42-3.6299c0.26888-2.5544-0.13444-12.1-0.13444-12.1s-0.53777-5.781 3.4955-2.8233c4.0332 2.9577 6.0499 4.1677 6.0499 4.1677z");
paths.push("m216.32 238.36s20.57 3.7644 24.603 5.9154c4.0332 2.1511 4.4366 1.6133 6.8565 2.42 2.42 0.80665 4.3021-0.26888 4.0333 3.7644-0.26889 4.0333-2.5544 15.73-2.5544 15.73l-1.7477 8.3354s-1.6133 4.3021-5.2432 2.5544-31.325-17.074-31.325-17.074-3.361 0.26889-2.8233-2.42c0.53777-2.6888 1.6133-7.5287 1.8822-8.201 0.26888-0.67221 2.0166-6.1843 2.2855-7.1254 0.26888-0.94109-0.94109-4.571 0.53777-4.571s3.4955 0.67221 3.4955 0.67221z");
paths.push("m87.387 207.97 39.123-6.4532s0.80665-0.13444 1.4789 0.94109c0.67221 1.0755 6.991 18.015 6.991 18.015s0.53777 1.21-0.26888 1.4789c-0.80665 0.26888-38.988 13.713-38.988 13.713s-0.26888 0.26889-1.21-2.42c-0.94109-2.6888-6.991-23.124-6.991-23.124z");
paths.push("m141.57 203.67 22.586-19.763s3.2266-2.2855 3.361 4.7055c0.13444 6.991 0.53777 11.428 0.53777 11.428s0 1.3444-2.0166 2.9577-17.881 15.864-17.881 15.864-2.8233 2.8233-3.6299-0.26888c-0.80665-3.0922-3.0922-11.696-3.0922-11.696z");
paths.push("m178.54 182.03s23.393 10.755 26.082 13.041c2.6888 2.2855 3.7644 1.8822 3.8988 4.9744 0.13444 3.0922 0 7.6632-0.67221 11.293-0.67221 3.6299 0.26888 4.8399-2.1511 4.7055-2.42-0.13445-4.4366-0.40333-7.2599-2.1511s-19.36-12.503-19.36-12.503-2.1511-2.0166-1.7477-2.6888c0.40332-0.67221 0-15.192 0-15.192z");
paths.push("m220.62 202.06s18.284-0.67221 20.973-0.40333c2.6888 0.26888 4.571 0.26888 5.3777 0.26888 0.80665 0 0.67221 0.80665 2.1511 0.9411 1.4789 0.13444 2.0166-0.26889 2.6888-0.67221 0.6722-0.40333 3.7644-1.0755 4.8399-0.26889 1.0755 0.80665 2.5544 0.40333 2.42 3.4955-0.13444 3.0922-1.3444 7.2599-1.8822 11.428-0.53777 4.1677-2.5544 7.1254-2.5544 8.201 0 1.0755 0.26889 2.1511 0.26889 2.1511s-2.0166 0.40332-3.6299 0.53776c-1.6133 0.13445-7.5287-1.6133-8.4698-1.7477-0.94109-0.13444-3.2266-0.67221-5.9154-1.0755-2.6888-0.40333-1.21-0.40333-4.8399-0.67221-3.6299-0.26889-4.8399-0.40333-6.8565-0.40333s-3.361 0.67221-4.9743-0.53777-1.6133-0.53776-2.2855-1.6133c-0.67221-1.0755-0.53777-0.26888-0.80665-2.5544-0.26889-2.2855-0.40333-5.781-0.13445-6.7221 0.26889-0.9411 0-7.2599 0.40333-7.9321 0.40332-0.67221 0.13444-1.8822 1.0755-2.1511 0.9411-0.26888 2.1511-0.26888 2.1511-0.26888z");
paths.push("m81.875 164.68 39.391 1.4789s0.40333 12.503 1.3444 16.402c0.9411 3.8988 2.1511 5.1088 0.9411 5.781-1.21 0.67221-16.267 3.4955-22.317 4.1677-6.0499 0.67221-10.621 0.40332-13.31 0.40332h-4.7055z");
paths.push("m133.5 173.15 27.964-24.334s2.1511-1.4789 2.42 0.80665c0.26889 2.2855 1.0755 16.536 1.0755 16.536s-0.40333 2.2855-1.6133 3.0922c-1.21 0.80665-24.468 19.225-24.468 19.225s-2.42 1.4789-2.8233-0.13444c-0.40333-1.6133-2.5544-15.192-2.5544-15.192z");
paths.push("m178.14 146.13 26.754 13.848s2.2855-0.53776 2.42 1.21c0.13444 1.7478 0.94109 19.36 0.94109 19.36s-0.94109 3.361-4.0332 1.21c-3.0922-2.1511-27.157-18.284-27.157-18.284s-1.8822-1.21-1.7477-3.2266c0.13444-2.0166-1.21-12.772-1.21-12.772s-0.53777-1.21 0.40333-1.7478c0.94109-0.53777 2.9577-0.53777 2.9577-0.53777z");
paths.push("m222.9 169.25s6.1843-1.8822 8.7387-2.0166c2.5544-0.13444 3.2266-1.21 5.781-1.4789 2.5544-0.26888 3.2266-0.53776 5.1088-0.26888s1.21 0.40333 2.9577 0.13444c1.7477-0.26888 4.0332-1.8822 5.6466-2.1511 1.6133-0.26888 2.1511-0.94109 4.0332 0.26889 1.8822 1.21 2.0166 2.42 2.1511 3.2266 0.13444 0.80665 1.21 16.671 1.21 16.671s-0.80665 4.0332-2.6888 4.3021c-1.8822 0.26889-25.678 0.9411-25.678 0.9411s-2.0166-1.4789-3.4955-0.67221c-1.4789 0.80665-2.5544 0.80665-3.4955 0.80665s-1.21-0.13444-1.21-0.13444-0.67221 0.13444-1.21-0.26889c-0.53776-0.40332-1.8822 0.67221-1.8822-2.5544v-13.713s-0.80665-1.6133 1.0755-2.1511c1.8822-0.53776 2.9577-0.94109 2.9577-0.94109z");
paths.push("m93.975 124.22s18.284 4.9744 21.376 5.1088c3.0922 0.13444 5.5121 0.94109 5.5121 0.94109s2.2855-0.26888 2.0166 1.21c-0.26889 1.4789-1.21 12.503-1.0755 14.923 0.13444 2.42-0.40332 4.4366-1.8822 4.9744-1.4789 0.53777-4.3021 0.80665-7.6632 0.80665-3.361 0-7.6632 0-10.218-0.13444-2.5544-0.13444-4.571-1.0755-6.991-1.4789-2.42-0.40333-1.8822-0.80665-5.781-1.21-3.8988-0.40332-5.2432 0.26888-6.4532-0.80665s-2.0166-0.80665-1.7477-3.7644c0.26888-2.9577 0-3.8988 0.53777-6.8565s0.94109-5.3777 1.8822-8.4698c0.94109-3.0922 0.40333-3.8988 1.7477-5.1088s0.26888-1.7477 2.6888-1.7477c2.42 0 3.7644 0.40332 4.7055 0.80665 0.94109 0.40332 1.3444 0.80665 1.3444 0.80665z");
paths.push("m158.78 110.1-24.603 22.048-0.67221 21.107s-0.13444 0.9411 0.94109 0.67221c1.0755-0.26888 26.754-22.855 26.754-22.855s1.21-1.0755 0.80665-5.6466c-0.40333-4.571-1.4789-14.923-1.4789-14.923z");
paths.push("m174.1 108.89 16.805 8.8732s1.0755 0 2.9577 0.53777 1.3444-1.4789 3.2266 1.8822c1.8822 3.361 1.0755 0.80665 2.6888 4.8399 1.6133 4.0333 3.0922 5.781 3.0922 9.142s-0.94109 4.571 0.26889 5.5121c1.21 0.94109 1.8822 1.4789 1.3444 2.5544-0.53776 1.0755-0.13444 1.6133-1.3444 1.7478-1.21 0.13444-29.443-15.192-29.443-15.192s-0.80665 1.0755-0.80665-3.4955-0.67221-13.31-0.67221-13.31-0.80665-3.0922-0.13445-3.0922h2.0166z");
paths.push("m218.87 134.57s4.8399-3.4955 9.9487-4.571 4.9744-1.8822 7.6632-1.6133c2.6888 0.26888 2.2855 0.80665 3.7644-0.13445 1.4789-0.94109 0-1.7477 2.2855-2.2855 2.2855-0.53776 3.4955-0.80665 5.2432-0.80665s3.6299-0.13444 4.0333 2.42c0.40332 2.5544 2.1511 9.8142 2.42 13.31 0.26888 3.4955 1.0755 4.7055 0.67221 6.1843-0.40333 1.4789-2.8233 3.0922-4.571 3.0922-1.7478 0-8.4698 1.3444-10.621 1.7478-2.1511 0.40332-6.7221 1.6133-8.4698 1.7477-1.7478 0.13444-2.6888-0.13444-4.8399 0.40332-2.1511 0.53777-2.8233-0.13444-3.4955 0.9411-0.67221 1.0755-1.21 2.42-2.9577 2.2855-1.7477-0.13444-2.5544-0.40333-2.8233-1.4789-0.26889-1.0755-0.53777-3.4955-0.80666-6.8565-0.26888-3.361 0-4.0333-0.13444-6.1843-0.13444-2.1511-0.94109-3.6299-1.21-4.0332-0.26889-0.40333-0.13445-1.8822 0.80665-2.6888 0.94109-0.80665 3.0922-1.4789 3.0922-1.4789z");
paths.push("m100.97 82.404 27.426 11.293s3.2266 1.3444 2.8233 2.5544c-0.40333 1.21-1.7477 2.6888-2.5544 6.3188-0.80666 3.6299-1.8822 7.5287-1.6133 8.6043 0.26889 1.0755-0.80665 4.3021-3.7644 3.4955-2.9577-0.80665-9.8142-3.0922-11.428-3.0922-1.6133 0-9.2765-1.7478-10.218-1.7478-0.94109 0-0.67221-0.13444-2.9577-1.3444s-3.7644-0.80664-6.1843-1.0755c-2.42-0.26888-3.7644 0.13444-3.8988-0.80665-0.13444-0.94109 0-3.2266 0.67221-5.3777s4.571-16.805 4.571-16.805-0.13444-2.5544 1.8822-2.6888c2.0166-0.13444 5.2432 0.67221 5.2432 0.67221z");
paths.push("m155.01 81.194s-4.3021 2.5544-6.1843 4.7055-4.3021 6.5876-5.2432 8.8732c-0.9411 2.2855-2.1511 4.8399-2.5544 6.991-0.40332 2.1511-1.3444 4.4366-1.3444 5.1088 0 0.67221-0.26888 2.2855 0.9411 2.2855s2.0166-0.80666 2.8233-1.6133c0.80665-0.80665 3.6299-4.7055 4.9743-6.3188s1.6133-2.5544 4.7055-4.571 4.1677-2.8233 4.8399-3.361c0.67221-0.53777 1.4789-1.3444 1.4789-1.3444s1.21-3.0922 0.53777-4.7055c-0.67221-1.6133-1.0755-3.7644-1.4789-4.7055-0.40333-0.94109 0.13444-1.21-1.0755-1.4789-1.21-0.26888-2.42 0.13444-2.42 0.13444z");
paths.push("m172.62 78.505s9.2765 6.1843 13.713 13.848c4.4366 7.6632 5.1088 9.8143 5.1088 9.8143s0.26888 1.21-0.9411 1.21-2.8233-2.5544-4.4366-3.2266c-1.6133-0.67221-7.3943-6.0499-9.8142-6.991-2.42-0.94109-5.6466-2.1511-5.6466-2.1511l-0.67221-0.13444-0.13444-11.562s0.53777-0.67221 1.3444-0.67221c0.80665 0 1.4789-0.13444 1.4789-0.13444z");
paths.push("m207.17 98.672 24.737-13.041s1.8822-2.2855 3.4955-0.53777 7.2599 17.746 7.2599 17.746 2.8233 4.9744-0.94109 6.0499-21.376 9.142-21.376 9.142-2.1511-0.40333-3.7644 1.21-4.9744 5.3777-6.1843 0.9411c-1.21-4.4366-4.8399-15.326-5.9154-15.595-1.0755-0.26888-1.8822-3.8988-1.21-4.1677 0.67221-0.26889 3.8988-1.7477 3.8988-1.7477z");
paths.push("m99.894 66.029s10.077-23.386 25.858-33.463 23.006-14.83 31.181-14.83c8.1756 0 23.196 1.7112 38.406 12.549 15.21 10.837 21.675 16.541 26.618 24.717 4.9434 8.1756 7.415 13.309 7.0348 14.26-0.38026 0.95065-29.66 18.062-29.66 18.062s-3.2322 1.3309-3.9927 0.19013c-0.76051-1.1408-6.0841-10.077-11.218-13.879-5.1335-3.8026-12.739-7.2249-19.393-6.8447-6.6545 0.38026-12.549 0.19013-18.633 6.2743-6.0842 6.0841-7.6052 7.7953-7.6052 7.7953s-1.3309 2.6618-3.0421 1.9013c-1.7-0.761-34.6-14.831-34.6-14.831z");

const BootPrints = ({flip, x, y, progress}) => {
    const filledColor = "#FF0000";
    const unfilledColor = "#000000"
    return (
        <svg width="1000" height="400" viewBox="0 0 250 1000">
            <g stroke="#000" strokeWidth="1px" transform={"translate(-600, 200), rotate(90)"}>
                {paths.map((p,index)=> <path key={index} fill={index < progress ? filledColor: unfilledColor} d={paths[index]} />)}
            </g>            
            <g stroke="#000" strokeWidth="1px" transform={"translate(-300, 300), scale(-1, 1), rotate(-90)"}>
                {paths.map((p,index)=> <path key={index} fill={index + 34 < progress ? filledColor: unfilledColor} d={paths[index]} />)}
            </g>          
            <g stroke="#000" strokeWidth="1px" transform={"translate(0, 200), rotate(90)"}>
                {paths.map((p,index)=> <path key={index} fill={index + 68 < progress ? filledColor: unfilledColor} d={paths[index]} />)}
            </g>
            <text x="0" y="20" fontSize="2em" fill="black" fontFamily="Calibri, serif">6 / 68 miles</text>
        </svg>
    )
}

export default ({  }) => {

    const [current, setCurrent]  = useState(0);

    return (
        <Grid container spacing={4} direction="row" alignItems="flex-start" sx={{padding: 1, margin: 1}}>
            <Grid item onClick={()=>setCurrent(current+1)} justifyContent={"center"} sx={{bgcolor: "pink"}}>
                <BootPrints
                    flip={true}
                    x={0}
                    y={0}
                    progress={current}
                />
            </Grid>
        </Grid>
    )
}