import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { firebaseConfig } from "../config/firebase.config";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getFirestore } from "firebase/firestore";
import { useEffect, useState } from "react";
import ChoreDataUtils from '../util/ChoreDataUtils';
import moment from 'moment';

import { collection, getDocs, doc, getDoc, onSnapshot, query, orderBy } from "firebase/firestore";

export const Firebase = initializeApp(firebaseConfig);
export const auth = getAuth();
// export const Providers = { google: new GoogleAuthProvider() };

export const db = getFirestore(Firebase);
export const analytics = getAnalytics(Firebase);

if (process.env.NODE_ENV !== 'production') {
  window.FIREBASE_APPCHECK_DEBUG_TOKEN = true
}

export const useNewFamilyData = (groupID) => {
  const [familyData, setFamilyData] = useState({});

  useEffect(() => {
    let mounted = true;
    const getFamilyData = async() => {
      
      let results = {};
      const docSnapshot = await getDoc(doc(db, "FamilyData", groupID));
      if (docSnapshot.exists() && mounted) {  
        setFamilyData(docSnapshot.data());
      }
    };
    getFamilyData();
    return () => mounted = false;
  }, []);
  return familyData;
}

export const useFamilyDataListener = (groupID) => {
  const [familyData, setFamilyData] = useState({});

  useEffect(() => {
    let mounted = true;
    if (groupID !== "" && groupID !== null) {
      const unsub = onSnapshot(doc(db, "FamilyData", groupID), (doc) => {
          setFamilyData(doc.data());
      });
    }
    
    return () => {
      mounted = false;
    };
  }, []);
  return familyData;

}

export const useTemplateChoreData = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    let mounted = true;
    const getData = async() => {
      
      let results = {}
      const docSnap = await getDoc(doc(db, "Admin", "Templates"));
      if (docSnap.exists() && mounted) {
        setData(docSnap.data()["chores"]);
      }
    };
    getData();
    return () => mounted = false;
  }, []);

  return data;
}

export const useUserData = (groupID, user) => {
  const [userData, setUserData] = useState({});

  useEffect(() => {
    let mounted = true;
    const getUserData = async() => {
      
      let results = {}
      const docSnap = await getDoc(doc(db, "FamilyData", groupID, "members", user));
      if (docSnap.exists && mounted) {
        setUserData(docSnap.data());
      }
    };
    getUserData();
    return () => mounted = false;

  }, []);
  return userData;
}

export const useRequestsData = (groupID) => {
  const [requestData, setRequestData] = useState({});

  useEffect(() => {
    let mounted = true;
    const getRequestData = async() => {
      let results = {};
      const querySnapshot = await getDocs(collection(db, "FamilyData", groupID, "requests"));
      querySnapshot.forEach((task) => {
        results[task.id] = task.data();
      });
      if (mounted) {
        setRequestData(results);
      }
    };
    getRequestData();
    return () => mounted = false;

  }, []);
  return requestData;
}

//Custom Hook
export const useFirebaseAuthentication = () => {
    const [authUser, setAuthUser] = useState(null);

    useEffect(() =>{
       const unlisten = auth.onAuthStateChanged(
          authUser => {
            authUser
              ? setAuthUser(authUser)
              : setAuthUser(null);
          },
       );
       return () => {
           unlisten();
       }
    }, []);

    return authUser
}

// const appCheck = initializeAppCheck(Firebase, {
//   provider: new ReCaptchaV3Provider('6LeiywUfAAAAAP6YybdwQmPF7N4Q8NBxtxsx_X4f'),
//   isTokenAutoRefreshEnabled: true
// });

// export function onAuthStateChange(callback) {
//   return auth.onAuthStateChanged(user => {
//     if (user) {
//       callback({loggedIn: true, email: user.email});
//     } else {
//       callback({loggedIn: false});
//     }
//   });
// }

export function login(username, password) {
  return new Promise((resolve, reject) => {
      signInWithEmailAndPassword(auth, username, password)
        .then(() => {
        return resolve()
      })
      .catch(error => {
        return reject(error)}
        );
  });
}

export function logout() {
  auth.signOut();
}

